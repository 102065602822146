import React from 'react'

import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Button, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';

const fileExtensions = {
    slideshows: {
        icon: <SlideshowIcon />,
        extensions: [
            'ppt',
            'pptx',
            'key',
            'odp',
            'pps',
            'ppsx',
            'pot',
            'potx',
            'sxi',
            'thmx'
        ]
    },
    videos: {
        icon: <OndemandVideoIcon />,
        extensions: [
            'mp4',
            'mov',
            'avi',
            'wmv',
            'flv',
            'mkv',
            'webm',
            'mpeg',
            'mpg',
            'm4v',
            '3gp',
            'ogv',
            'mts',
            'm2ts',
            'vob',
            'ts',
            'divx',
            'rm',
            'asf',
            'f4v',
            'mxf'
        ]
    },
    images: {
        icon: <ImageIcon />,
        extensions: [
            'jpg',
            'jpeg',
            'png',
            'gif',
            'bmp',
            'tiff',
            'tif',
            'webp',
            'svg',
            'heic',
            'ico',
            'psd',
            'ai',
            'eps',
            'raw',
            'cr2',
            'nef',
            'orf',
            'sr2',
            'svgz',
            'dds',
            'xcf'
        ]
    },
    textFiles: {
        icon: <DescriptionIcon />,
        extensions: [
            'txt',
            'csv',
            'json',
            'xml',
            'log',
            'md',
            'ini',
            'yaml',
            'yml',
            'rtf',
            'tsv',
            'srt'
        ]
    }
};

/**
 * The file display. Clicking the button opens the file in a new tab
 * @param {Object} files the files in object form. The key is the file name, and it's value is an object with a key url2, which is the url 
 * @param {Boolean} displayName whether or not to display the file name, or just an icon
 * @param {String} color if included, it overrides the default color
 * @returns the file display
 */
const FileDisplay = ({ files, displayName, color }) => {

    /**
     * Gets the extension from a file name
     * @param {String} fileName the file name including the extension
     * @returns the extension
     */
    const getExtention = (fileName) => {
        const splitFileName = fileName.split('.')
        return splitFileName[splitFileName.length - 1]
    }

    /**
     * Gets the icon corresponding to the extension provided. If there isn't a match, it gives a default file icon
     * @param {String} ext the file extension
     * @returns the icon corresponding to the extension
     */
    const getExtentionIcon = (ext) => {
        console.log(ext)
        for (let extension of Object.keys(fileExtensions)) {
            console.log(fileExtensions[extension].extensions)
            if (fileExtensions[extension].extensions.includes(ext)) {
                console.log(extension)
                return fileExtensions[extension].icon
            }
        }
        return <InsertDriveFileIcon />
    }

    return (
        <div style={{ display: 'flex', width: '100%' }}>
            {Object.keys(files).map((file, index) => (
                <Link key={index} to={files[file].url2} target='_blank'>
                    {displayName ?
                        <Button
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                color: color || '#2d5ca9'
                            }}
                            startIcon={getExtentionIcon(getExtention(file))}
                        >
                            {file}
                        </Button> :
                        <IconButton sx={{ color: color || '#2d5ca9' }}>
                            {getExtentionIcon(getExtention(file))}
                        </IconButton>
                    }
                </Link>
            ))}
        </div>
    )
}

export default FileDisplay