import * as React from 'react';
// import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Button, IconButton, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControl } from '@mui/material';
import { setComboOptionEquipment, resetPageEquipment } from "../../store/combobox/equipmentSlice";
import { setState } from '../../store/loadstate/loadStateSlice';
import { useSelector, useDispatch } from 'react-redux';
import ApiManager from '../../api_utils/ApiManager';
import ApiError from '../../api_utils/ApiError';
import Table from '../../components/table/Table';
import { GridCloseIcon } from '@mui/x-data-grid';
// import { response } from 'express';

export default function Equipment() {
  const dataEquipment = useSelector(state => state.equipment.value)
  const dispatch = useDispatch();
  const isPopstate = useSelector(state => state.loadstate.value)

  const [isLoading, setIsLoading] = React.useState(false)
  const [isLoadingLog, setIsLoadingLog] = React.useState(false)
  const [rows, setRows] = React.useState([]);
  const [rowsLogs, setRowsLogs] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [columnsLogs, setColumnsLogs] = React.useState([]);
  const [selectedOption, setSelectedOption] = React.useState(dataEquipment.option);
  const [dropdownOptions, setDropdownOptions] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState(null); // New state for selected row data
  const [equipment_id, setEquipmentID] = React.useState('');
  const [formValues, setFormValues] = React.useState(null); // New state to track form changes
  const [initialValues, setInitialValues] = React.useState(null); // New state for initial form values
  const [fetchApiIndex, setFetchApiIndex] = React.useState(0)
  const [fetchLogsIndex, setFetchLogsIndex] = React.useState(0)
  const handleApiError = ApiError()

  function isNumeric(str) {
    if (typeof str != "string") return false
    return !isNaN(str) &&
      !isNaN(parseFloat(str))
  }

  function getConversionFactor(prefix) {
    switch (prefix) {
      case 'Y': return 1e24;
      case 'Z': return 1e21;
      case 'E': return 1e18;
      case 'P': return 1e15;
      case 'T': return 1e12;
      case 'G': return 1e9;
      case 'M': return 1e6;
      case 'k': return 1e3;
      case 'h': return 1e2;
      case 'da': return 1e1;
      case '': return 1;
      case 'd': return 1e-1;
      case 'c': return 1e-2;
      case 'm': return 1e-3;
      case 'μ': return 1e-6;
      case 'n': return 1e-9;
      case 'p': return 1e-12;
      case 'f': return 1e-15;
      case 'a': return 1e-18;
      case 'z': return 1e-21;
      case 'y': return 1e-24;
      default: throw new Error(`Unrecognized prefix: ${prefix}`);
    }
  }

  const handleChange = (key, event) => {
    setFormValues({
      ...formValues,
      [key]: event.target.value,
    });
  };

  const handleConfirm = async () => {
    if (formValues && initialValues && JSON.stringify(formValues) !== JSON.stringify(initialValues)) {
      // If CalibrationDate exists in formValues, format it to 'YYYY-MM-DD'
      if (formValues.CalibrationDate) {
        formValues.CalibrationDate = dayjs(formValues.CalibrationDate).format('YYYY-MM-DD');
      }

      await ApiManager.EQUIPMENT.PUT_DETAIL(formValues.id, formValues);
      // /  await axios.put(`s://${process.env.REACT_APP_SERVER_ROUTE}/equipment/${formValues.id}`, formValues);
      fetchLog();
    }
  };

  const fetchAPI = async (queries) => {
    setIsLoading(true)
    let endpoint = '';
    if (selectedOption !== '' && selectedOption !== null) {
      endpoint = `/${selectedOption}`;
    }
    dispatch(setComboOptionEquipment(selectedOption))
    const d = await ApiManager.EQUIPMENT.DETAIL(endpoint)
    const response = await handleApiError(d)
    console.log('sadsadsad', response)
    // const response = await axios.get(`https://${process.env.REACT_APP_SERVER_ROUTE}${endpoint}`);
    const data = response;
    console.log(data)
    if (Array.isArray(data) && data.length) {
      // setting up rows
      setRows(data.map((item, index) => ({ id: index, ...item })));

      // setting up columns
      const allKeys = data.reduce((keys, item) => {
        Object.keys(item).forEach((key) => {
          if (!keys.includes(key)) {
            keys.push(key);
          }
        });
        return keys;
      }, []);

      const dynamicColumns = allKeys.map((key) => {
        const fieldIsNumeric = data.some(item => isNumeric(item[key]));
        return {
          field: key,
          flex: 1,
          headerName: key.charAt(0).toUpperCase() + key.slice(1), // Capitalizing the header
          renderCell: (params) => <RenderCell {...params} />,
          hide: key.startsWith('prefix_') || key.startsWith('measurement_unit_'),  // Hide prefix and measurement unit columns
          ...(fieldIsNumeric && {
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
              return parseFloat(cellParams1.value) - parseFloat(cellParams2.value);
            },
          }),
        };
      }).filter((c) => !c.hide);
      setColumns(dynamicColumns);
      setFetchApiIndex(fetchApiIndex + 1)
    }
    setIsLoading(false)
  };

  const fetchLog = async () => {
    setIsLoadingLog(true)
    console.log("eqipment id", equipment_id)
    const d = await ApiManager.EQUIPMENT.DETAIL_LOG(equipment_id)
    const response = await handleApiError(d)
    // const response = await axios.get(`https://${process.env.REACT_APP_SERVER_ROUTE}/equipment/log/${equipment_id}`);
    const data = response;

    if (Array.isArray(data) && data.length) {
      // setting up rows
      setRowsLogs(data.map((item, index) => ({ id: index, ...item })));

      // setting up columns
      const dynamicColumns2 = Object.keys(data[0]).map((key) => ({
        field: key,
        headerName: key.charAt(0).toUpperCase() + key.slice(1), // Capitalizing the header
        flex: 1 / Object.keys(data[0]).length
      }));
      console.log(dynamicColumns2)
      setColumnsLogs(dynamicColumns2);
      setFetchLogsIndex(fetchLogsIndex + 1)
    }
    setIsLoadingLog(false)
  };

  const sendEquipmentType = (param) => {
    setSelectedOption(param);
    console.log(param);
  };

  React.useEffect(() => {
    fetchAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  React.useEffect(() => {
    if (equipment_id !== '') {
      fetchLog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipment_id]);

  React.useEffect(() => {
    const fetchDropdownOptions = async () => {
      console.log('here');
      const d = await ApiManager.EQUIPMENT.DETAIL_TYPELIST()
      const re = await handleApiError(d)
      console.log("SSSSSSSS")
      console.log(re)
      // const response = await axios.get(`https://${process.env.REACT_APP_SERVER_ROUTE}/equipment/typeslist`);
      setDropdownOptions(re);
      // dispatch(initializeComboBoxDataEquipment(re.data))
    };

    fetchDropdownOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getConvertedValue(row, fieldName) {
    let value = row[fieldName];

    if (value && isNumeric(value)) {
      const prefix = row['prefix_' + fieldName];
      const unit = row['measurement_unit_' + fieldName];

      // Check if prefix is valid before proceeding
      if (unit) {
        // Get conversion factor for the prefix
        const factor = getConversionFactor(prefix);

        // Convert Hz to the desired unit if field name includes 'Frequency' or 'Bandwidth' and the unit is 'Hz'
        if (unit === 'Hz') {
          value = (parseFloat(value) / factor).toFixed(2) + ' ' + prefix + 'Hz';
        }

        // Convert V to the desired unit if field name includes 'Voltage' and the unit is 'V'
        else if (unit === 'V') {
          value = (parseFloat(value) / factor).toFixed(2) + ' ' + prefix + 'V';
        }

        // Convert A to the desired unit if field name includes 'Current' and the unit is 'A'
        else if (unit === 'A') {
          value = (parseFloat(value) / factor).toFixed(2) + ' ' + prefix + 'A';
        }
      }
    }

    return value;
  }



  const RenderCell = (params) => {
    // console.log("Row data:", params.row);  // Debugging output
    // console.log("Field:", params.field);   // Debugging output

    let value = getConvertedValue(params.row, params.field);

    // console.log("Converted value:", value);  // Debugging output

    const tooltipTitle = value ? value.toString() : '';

    return (
      <Tooltip title={tooltipTitle} enterDelay={500}>
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
          }}
        >
          {value}
        </div>
      </Tooltip>
    );
  };

  React.useEffect(() => {
    const handlePopstate = () => {
      console.log("handle pop start");
      dispatch(setState(true));
    };

    window.addEventListener('popstate', handlePopstate);
    return () => window.removeEventListener('popstate', handlePopstate);
  }, [dispatch]);

  React.useEffect(() => {
    const handleBeforeUnload = (event) => {
      console.log("loading");
      if (!isPopstate) {
        // dispatch(setComboOption(""));
        dispatch(resetPageEquipment())
        // Additional logic for non-popstate scenarios
      }

      dispatch(setState(false));
      // event.preventDefault();
      event.returnValue = '';
    }

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div style={{ height: 600, width: '100%', paddingBottom: '50px' }}>
      <div>
        <h1 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px', fontSize: '50px' }}>Equipment</h1>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '20px' }}>
        <Autocomplete
          id="combo-box-demo"
          options={dropdownOptions}
          getOptionLabel={(option) => {
            console.log("option", option)
            if (!option) {
              return ""
            }
            if (typeof option === 'object' && 'type_name' in option) {
              return option.type_name
            }

            return option
          }}

          value={dataEquipment.option}

          onChange={(event, newValue) => {
            if (newValue === null || newValue === '') {
              sendEquipmentType('');
            } else {
              sendEquipmentType(newValue.type_name);
            }
          }}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Equipment Type" variant="outlined" />}
        />
      </div>
      <div style={{ width: '90vw', overflowX: 'scroll' }}>
        <Table
          key={fetchApiIndex}
          columns={columns}
          fetchData={() => {
            return { result: rows, size: rows.length }
          }}
          sx={{ height: '500px', width: columns.length * 180 }}
          id={"Equipment"}
          onRowClick={(param) => {
            setSelectedRow(param.row);
            setFormValues(JSON.parse(JSON.stringify(param.row)));
            setInitialValues(JSON.parse(JSON.stringify(param.row)));
            setEquipmentID(param.row.id)
          }}
          onCellClick={() => { }}
          doFetchIndex={fetchApiIndex}
          paginationMode={"client"}
          loading={isLoading}
        />
      </div>

      {selectedRow && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: "solid 2px #0a9dd9", position: 'relative', borderRadius: '15px', marginTop: '8px' }}>

          <div style={{ margin: '20px 20px 40px 40px' }}>
            <IconButton
              aria-label="close"
              onClick={() => {
                setSelectedRow(undefined)
              }}
              sx={{
                color: (theme) => "#ff0000",
                position: 'absolute',
                top: '8px',
                right: '8px'
              }}
            >
              <GridCloseIcon />
            </IconButton>
            <Typography variant="h6" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {selectedRow.Model && `Model: ${selectedRow.Model}`}<br />
              {selectedRow.SerialNumber && `Serial Number: ${selectedRow.SerialNumber}`}
            </Typography>
            {Object.entries(selectedRow).map(([key, value]) => {
              const editable = [
                'Manufacturer',
                'Model',
                'SerialNumber',
                'CalibrationDate',
                'Location',
                'Ownership',
                'Status',
                'Activity',
                'Options',
                'Notes'
              ].includes(key);
              if (key === 'CalibrationDate') {
                return (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormControl style={{ marginTop: 5, minWidth: 700 }}>
                      <DatePicker
                        label="CalibrationDate"
                        value={formValues ? formValues[key] : null}
                        onChange={(newValue) => {
                          setFormValues((prev) => ({
                            ...prev,
                            [key]: newValue,
                          }));
                        }}
                        renderInput={(params) => {
                          // parse date string to Date object
                          const dateValue = params.inputProps.value
                            ? dayjs(params.inputProps.value).format('YYYY-MM-DD')
                            : null;

                          return (
                            <TextField
                              {...params}
                              value={dateValue}
                              inputProps={{
                                readOnly: !editable,
                                style: { paddingLeft: '10px', height: 45 },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              style={{ marginTop: 10, minWidth: 700 }}
                            />
                          );
                        }}
                        disabled={!editable}
                      />
                    </FormControl>
                  </LocalizationProvider>
                );

              }
              return (
                <div key={key}>
                  <TextField
                    label={key.charAt(0).toUpperCase() + key.slice(1)}
                    value={formValues ? formValues[key] : ''}
                    onChange={editable ? (event) => handleChange(key, event) : null}
                    InputProps={{
                      readOnly: !editable,
                      style: { padding: '10px' }
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    style={{ marginTop: 20, minWidth: 700 }}
                  />
                  <br />
                </div>
              )
            })}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button variant="contained" style={{ color: 'white', backgroundColor: '#0275d8' }} onClick={handleConfirm}>Confirm</Button>

            </div>
          </div>



        </div>
      )}

      {selectedRow &&
        <Table
          columns={columnsLogs}
          fetchData={() => {
            return { result: rowsLogs, size: rowsLogs.length }
          }}
          sx={{ height: '500px' }}
          id={"EquipmentLogs"}
          onCellClick={() => { }}
          doFetchIndex={fetchLogsIndex}
          paginationMode={"client"}
          loading={isLoadingLog}
          closeButton={(() => {
            setSelectedRow(undefined)
          })}
        />
      }
    </div>
  );
}
