import React, { useEffect, useRef, useState } from 'react'
import Table from '../table/Table';
import ApiManager from '../../api_utils/ApiManager';
import ApiError from '../../api_utils/ApiError';
import HoldButton from '../holdbutton/HoldButton';
import dayjs from 'dayjs';
import { Box, Card, IconButton, LinearProgress, Modal, Paper, styled, Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ClearIcon from '@mui/icons-material/Clear';
import { convertCommentToReact } from '../comment/CommentToReact';
import TemplateModal from '../../containers/template/TemplateModal';
import DisplayDataRequestModal from '../modal/DisplayDataRequestModal';
import ProgressBar from '../progressbar/ProgressBar';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const style = {
    position: 'absolute',
    borderRadius: '25px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1100px',
    height: "500px",
    bgcolor: 'white',
    p: 20,
    overflowY: 'auto',
    '&::-webkit-scrollbar': { width: 0, }
};

const AggregatedLog = ({ partNumbers, includePartNumber, tableID }) => {
    const handleApiError = ApiError()
    const refreshRef = useRef(null)

    const columns = [
        ...(includePartNumber ? [{
            field: 'part_number',
            headerName: "Part Number",
            flex: 0.125,
            renderCell: (params) => (
                <HoldButton url={`/part?partNum=${params.value}`} params={params}>
                    {params.value}
                </HoldButton>
            ),
        }] : []),
        {
            field: 'serial_number',
            headerName: "Serial Number",
            flex: 0.125,
            renderCell: (params) => (
                <HoldButton url={`/log/${params.value}?sid=${params.row.sid}`} params={params}>
                    {params.value}
                </HoldButton>
            )
        },
        {
            field: 'recorded_timestamp',
            headerName: 'Time Stamp',
            flex: 0.125,
            type: 'dateTime',
            valueGetter: ({ value }) => value && new Date(value)
        },
        {
            field: 'type',
            headerName: 'Action',
            flex: 0.125
        },
        {
            field: 'activity',
            headerName: 'Logs',
            flex: 0.5,
            editable: true
        }
    ];

    const columns2 = [
        { field: 'test_timestamp', headerName: 'Timestamp', flex: 0.0625, minWidth: 150 },
        { field: 'test_name', headerName: 'Test', flex: 0.125, minWidth: 250 },
        { field: 'result', headerName: 'Result', flex: 0.125, minWidth: 200 },
        { field: 'comment', headerName: 'Comment', flex: 0.125, minWidth: 250 },
        { field: 'measurement', headerName: 'Measurement', flex: 0.0625, minWidth: 150 },
        { field: 'units', headerName: 'Units', flex: 0.0625, minWidth: 150 },
        { field: 'upperbound', headerName: 'Upper Limit', flex: 0.0625, minWidth: 150 },
        { field: 'lowerbound', headerName: 'Lower Limit', flex: 0.0625, minWidth: 150 },
        { field: 'eridan_upperbound', headerName: 'Eridan Upper Limit', flex: 0.0625, minWidth: 150 },
        { field: 'eridan_lowerbound', headerName: 'Eridan Lower Limit', flex: 0.0625, minWidth: 150 },
        { field: 'machine', headerName: 'Machine', flex: 0.0625, minWidth: 150 },
        { field: 'software_version', headerName: 'Software Version', flex: 0.0625, minWidth: 150 }
    ]

    const [isLoading, setLoading] = useState(true)
    const [isLoadingTable2, setLoadingTable2] = useState(true)
    const [rows, setRows] = useState([])
    const [rows2, setRows2] = useState([])


    const fetchLogs = async () => {
        if (partNumbers && partNumbers.length > 0) {
            setLoading(true)
            ApiManager.STATUS.LOGS(partNumbers.map((part) => part.pid))
                .then(handleApiError)
                .then((rows) => {
                    setLoading(false)
                    setRows(rows.map((row, index) => ({ ...row, id: index })))
                })

            if (refreshRef.current) {
                clearInterval(refreshRef.current)
            }

            refreshRef.current = setTimeout(fetchLogs, 3600000 - (Date.now() % 3600000))
        } else {
            setLoading(false)
            setRows([])
        }
    }

    const [testResult, settestResult] = React.useState({});
    const [openTest, setOpenTest] = React.useState(false);
    const [openRequest, setOpenRequest] = React.useState(false);
    const [testFiles, setTestFiles] = React.useState({});

    const [displayOpenTest, setDisplayOnTest] = React.useState(false);
    const [displayDataTest, setDataTest] = React.useState({});
    const [displayDataRequest, setDataRequest] = React.useState({});

    const handleCloseTest = () => {
        setDisplayOnTest(false)
    }

    const handleTest = async (e) => {
        setLoading(true)
        const obj = {
            test_id: e.row.id
        };

        try {
            console.log('data', obj)

            const d = await ApiManager.LOG.TESTRESULT(obj)
            console.log('data', d)
            const data = await handleApiError(d)
            settestResult(e.row)
            setDisplayOnTest(true)
            setDataTest(data)
            setLoading(false)
        }
        catch (error) {
            setLoading(false)
        }
    }

    const getTest = async (ref_id, serial_name) => {
        console.log('refff', ref_id)

        setLoading(true)

        setOpenTest(true)
        setOpenRequest(false)

        const obj = {
            test_id: ref_id,
            serial_number: serial_name
        };
        try {
            const d = await ApiManager.STATION.STATIONTEST(obj)
            const data = await handleApiError(d)

            setRows2(data.tests)
            setTestFiles(data.test_files)
            console.log(data.test_files, "asdlkaslk")
            setLoading(false)

            window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
        }
        catch (error) {
            setLoading(false)
        }

    }
    const handleRowClick = async (e) => {
        setLoading(true)
        const serial_name = e.row.serial_number

        console.log("e.row", e.row)

        if (e.row.type.includes('Request') || e.row.type.includes('Note')) {
            setOpenTest(false)
            let body = {}
            if (e.row.type.includes('Request')) {
                body = {
                    request_id: e.row.ref_id.replace("REQUEST_", ""),
                    serial_number: serial_name
                }
                const requestReponse = await ApiManager.QUEUE.GETREQUEST(body)
                const requestData = await handleApiError(requestReponse)
                if (requestData === "Unauthorized") return

                console.log("requestData", requestData)
                let today = dayjs(requestData.fixed_time)

                requestData.timelapse = parseFloat(today.diff(requestData.request_time, 'hour', true)).toFixed(2)
                requestData['request_id'] = e.row.ref_id
                requestData['serial_number'] = serial_name
                setDataRequest({})
                setDataRequest(requestData)
                setLoading(false)

                setOpenRequest(true)

                console.log("requestData", requestData)
            }
            else if (e.row.type.includes('Note')) {
                body = {
                    note_id: e.row.ref_id.replace("NOTE_", ""),
                    serial_number: serial_name
                }

                const noteReponse = await ApiManager.LOG.GETNOTE(body)
                const noteData = await handleApiError(noteReponse)
                noteData['request_id'] = e.row.ref_id
                noteData['serial_number'] = serial_name
                setDataRequest({})
                setDataRequest(noteData)
                setLoading(false)

                setOpenRequest(true)
            }

        }
        else if (e.row.type.includes('STATUS')) {
        }
        else {
            const ref_id = e.row.ref_id.replace("TEST_", "")
            getTest(ref_id, serial_name)

        }

    }

    useEffect(() => fetchLogs, [partNumbers])

    return (
        <>
            <div style={{ marginTop: '10px' }} />
            {isLoading ? <LinearProgress /> : <div style={{ height: '4px' }}></div>}
            <Table
                sx={{
                    height: 600
                }}
                fetchData={() => { return { result: rows, size: rows.length } }}
                columns={columns}
                paginationMode={"client"}
                id={"Log1"}
                doFetchIndex={rows}
                onCellClick={() => { }}
                getRowClassName={(params) => {
                    switch (params.row.type) {
                        case "TestFIRSTTIMEPASS":
                            return 'TableRow-Pass'
                        case "TestRETESTPASS":
                            return 'TableRow-Pass'
                        case "TestFAIL":
                            return 'TableRow-Fail'
                        case "RequestClosed":
                            return 'TableRow-RequestClosed'
                        case "TestERIDANPASS":
                            return 'TableRow-EridanPass'
                        case "Note":
                            return 'TableRow-Note'
                        case 'RequestOpen':
                            return 'TableRow-RequestOpen'
                        default:
                            return undefined
                    }
                }}
                location={tableID}
                onRowClick={handleRowClick}
            />

            {rows2.length > 0 && (
                <>
                    <Card sx={{ maxWidth: "100%", border: 'black 1px solid', margin: '15px', }}>
                        <Stack direction="column" spacing={2}>
                            <Item style={{ background: "#2D5CA9", color: 'white' }}>
                                <div style={{ fontSize: "24px" }}>Test Files:</div>
                            </Item>
                            {testFiles && Object.keys(testFiles).map((k, index) => (
                                <div key={k} style={{ fontSize: '20px', display: 'flex', alignItems: 'center' }}>
                                    <a style={{ textDecoration: 'none', marginRight: '10px' }} href={testFiles[k]['url2']} target="_blank" rel="noopener noreferrer">
                                        <CloudDownloadIcon style={{ marginLeft: '10px', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </a>
                                    <a style={{ textDecoration: 'none', marginRight: '10px' }} href={testFiles[k]['url1']} target="_blank" rel="noopener noreferrer">
                                        <VisibilityIcon style={{ fontSize: '30px', verticalAlign: 'middle' }} />
                                    </a>
                                    <span style={{ verticalAlign: 'middle' }}><a href={testFiles[k]['url1']} target="_blank" rel="noopener noreferrer">{k}</a></span>
                                </div>
                            ))}
                            {(!testFiles || Object.keys(testFiles).length === 0) && (
                                <div style={{ fontSize: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                    <div>No files  :(</div>
                                    <div style={{ marginRight: '10px', fontSize: '30px' }}></div>
                                </div>
                            )}
                        </Stack>
                    </Card>
                    <Table
                        loading={isLoadingTable2}
                        sx={{
                            height: 600
                        }}
                        fetchData={() => { return { result: rows2, size: rows2.length } }}
                        columns={columns2}
                        paginationMode={"client"}
                        id={"Log2"}
                        doFetchIndex={rows2}
                        onCellClick={() => { }}
                        getRowClassName={(params) => {
                            switch (params.row.result) {
                                case "FIRSTTIMEPASS":
                                    return 'TableRow-Pass'
                                case "RETESTPASS":
                                    return 'TableRow-Retest'
                                case "FAIL":
                                    return 'TableRow-Fail'
                                default:
                                    return undefined
                            }
                        }}
                        location={`${tableID}-2`}
                        onRowClick={handleTest}
                    />
                </>
            )}

            {openRequest && <DisplayDataRequestModal displayDataRequest={displayDataRequest} setOpenRequest={setOpenRequest} setDataRequest={setDataRequest} openRequest={openRequest} handleApiError={handleApiError} />}

            <TemplateModal openTest={openTest} displayOpenTest={displayOpenTest} handleCloseTest={handleCloseTest} testResult={testResult} displayDataTest={displayDataTest} />

        </>
    )
}

export default AggregatedLog