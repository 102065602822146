import ApiMethods from "../ApiMethods";
import ENDPOINTS from "../EndPoints";

class LogManager {
	static ENDPOINTS = {
		LOG_GETLOGACTIVITY: () => `/getactivity`,
		LOG_GETLOGREV: () => `/revs`,
		LOG_GETSERIALSUMMARY: () => `/getserialsummary`,
		LOGTEMPLATE_GETTESTRESULT: () => `/gettestresult`,
		ADDNOTE: () => `/addnote`,
		GETNOTE: () => `/getnote`,
		ADDCOMMENT: () => `/addcomment`,
	};

	static REV_BYPARTNUM = (params) => {
		const url =
			ENDPOINTS.BASE_URL +
			this.ENDPOINTS.LOG_GETLOGREV() +
			`/${params.partNum}/uprev/${params.serialRev}/${params.latestRev}`;
		return ApiMethods.get(url);
	};

	static REVDATA = (params) => {
		const url =
			ENDPOINTS.BASE_URL +
			this.ENDPOINTS.LOG_GETLOGREV() +
			`/${params.partNum}/${params.id}`;
		return ApiMethods.get(url);
	};

	static UPREVDATA = () => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.LOG.UPREVDATA();
		return ApiMethods.get(url);
	};

	static ACTIVITY = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.LOG_GETLOGACTIVITY();
		return ApiMethods.post(url, body);
	};

	static SERIALSUMMARY = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.LOG_GETSERIALSUMMARY();
		return ApiMethods.post(url, body);
	};

	static TESTRESULT = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.LOGTEMPLATE_GETTESTRESULT();
		return ApiMethods.post(url, body);
	};

	static GETNOTE = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.GETNOTE();
		return ApiMethods.post(url, body);
	};

	static ADDNOTE = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.ADDNOTE();
		return ApiMethods.post(url, body);
	};

	static ADDCOMMENT = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.ADDCOMMENT();
		return ApiMethods.post(url, body);
	};
}

export default LogManager;
