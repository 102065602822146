import React, { useState, useEffect } from 'react';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ClearIcon from '@mui/icons-material/Clear';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Autocomplete, Divider } from '@mui/material';

import TextField from '@mui/material/TextField';
import { FormControl, IconButton, List, ListItem, ListItemText } from '@mui/material';
import ApiManager from '../../api_utils/ApiManager';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2D5CA9',
    },
    background: {
      default: '#fff',
    },
  },
});

export default function AddComponentsForm({setisSet , handleApiError}) {

  const ListItem1 = ({ term }) => {
    const exists = componentList.some(str => {
        const [leftPart] = str.split(' |');
        return leftPart === term.name;
    });

    const baseStyle = {
        color: 'white',
        padding: '8px 15px',
        marginBottom: '5px',
        borderRadius: '5px',
        fontSize: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRight: '3px solid white'
    };

    const satisfiedStyle = {
        ...baseStyle,
        backgroundColor: 'green',
    };

    const unsatisfiedStyle = {
        ...baseStyle,
        backgroundColor: 'red',
    };

    return (
        <li style={exists ? satisfiedStyle : unsatisfiedStyle}>
            <span>{term.name}</span>
            <span style={{marginLeft: '20px', paddingLeft: '20px', borderLeft: '2px solid white'}}>Count: {term.count}</span>
        </li>
    );
}

  

  const [componentList, setComponentList] = useState([]);
  const [lookupSerialValue, setLookupSerialValue] = useState('');
  // const [allSerials, setAllSerials] = useState([]);
  const [serialOptions, setSerialOptions] = useState([]);
  const [allComponents, setAllComponents] = useState([]);
  const [criteria, setCriteria] = useState([]);
  const temp = ["ER981-001009-001 | OK23-0900001" , "ER981-001010-001 | DdkVe231400010" , "ER981-001070-001 | n78RFFE20221111002", "ER981-001011-001 | DPS6202022024"]
  let checker = temp.some(str => str.startsWith("ER981-001010-001"))
console.log("checking" , checker)

  useEffect(() => {
    if (lookupSerialValue && lookupSerialValue.length > 2) {
      fetchComponentData(lookupSerialValue, false);
    }
  }, [lookupSerialValue]);

  // useEffect(() => {
  //   console.log("Options:", allSerials)
  //   setSerialOptions(allSerials);
  // }, [allSerials]);

  
  const [partNumbers, setPartNumberOptions] = useState([]);
  const [lookupPartValue, setLookupPartValue] = useState('');
  // const [isError, setisError] = useState('');




  useEffect(() => {
      if (lookupPartValue && lookupPartValue.length > 2) {
        fetchPartData(lookupPartValue);
      }
    }, [lookupPartValue]);

  const fetchPartData = async (value) => {
      var b = {
        part_number: value
      };

      const d = await ApiManager.INTAKE.PARTS.SEARCH(b)
      const data = await handleApiError(d)
      //     console.log(data)

      setPartNumberOptions(data.map(item => item.name));

      // fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/partsearchlist`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json"
      //   },
      //   body: JSON.stringify(b)
      // })
      //   .then(response => response.json())
      //   .then(data => {
      //     console.log(data)

      //     setPartNumberOptions([...data])
      //   })
      //   .catch(error => {
      //     console.error("Error:", error);
  
  
      //   });
    }

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(componentList)
    var b = {
      parent_serial_number: lookupSerialValue,
      parent_part_number: lookupPartValue,
      serials: componentList
    }

    const d = await ApiManager.INTAKE.COMPONENTS.ADD(b)
    const data = await handleApiError(d)
    console.log(data)
    setisSet(true)
    // fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/addcomponents`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json"
    //   },
    //   body: JSON.stringify(b)
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     console.log(data)
    //     setisSet(true)
    //   })
    //   .catch(error => {
    //     console.error("Error:", error);
    //   });
  };

  const handleAddComponent = (component) => {
    if (component && component.length > 0) {
      const lastComponent = component.pop();
      setComponentList([...componentList, lastComponent]);
    }
  };

  const getExistingComponents = async (value) => {
    var b = {
      serial_number: value,
      part_number: lookupPartValue
    };
    const d = await ApiManager.INTAKE.COMPONENTS.GET(b)
    const data = await handleApiError(d)
    setComponentList(data["components"])
    setCriteria(data["criteria"])
    console.log("COMPSS",data)

    // fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/getComponents`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json"
    //   },
    //   body: JSON.stringify(b)
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     setComponentList(data["components"])
    //     setCriteria(data["criteria"])
    //     console.log("COMPSS",data)
    //   })
    //   .catch(error => {
    //     console.error("Error:", error);
    //   });
  }

  const fetchData = async (value, lookup) => {
    var b = {
      serial_number: value,
      part_number: lookupPartValue
    }
    const d = await ApiManager.INTAKE.SERIALS.SEARCH(b)
    const data = await handleApiError(d)
    setSerialOptions(data)
    console.log(data)
    // fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/serialsearchlist`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json"
    //   },
    //   body: JSON.stringify(b)
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     setSerialOptions(data)
    //     console.log(data)
    //   })
    //   .catch(error => {
    //     console.error("Error:", error);
    //   });
      

  }

  const fetchComponentData = async (value, lookup) => {
    var b = {
      serial_number: value,
    }

    const d = await ApiManager.INTAKE.COMPONENTS.SEARCH(b)
    const data = await handleApiError(d)
    setAllComponents(data)
    console.log("Comps",data)
    // fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/componentsearchlist`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json"
    //   },
    //   body: JSON.stringify(b)
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     setAllComponents(data)
    //     console.log("Comps",data)
    //   })
    //   .catch(error => {
    //     console.error("Error:", error);


    //   });

    //DUMP
      // fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/kitting/requirements`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json"
      //   },
      //   body: JSON.stringify(b)
      // })
      //   .then(response => response.json())
      //   .then(data => {
      //     setCriteria(data)
      //     console.log("Comps",data)
      //   })
      //   .catch(error => {
      //     console.error("Error:", error);
  
  
      //   });
  }
useEffect( ()=> {
console.log("AAA")
}, [componentList])

  const handleRemoveComponent = (index) => {
    const newList = [...componentList];
    newList.splice(index, 1);
    setComponentList(newList);
  };

  if( allComponents === null ) return ;
  const availableComponents = allComponents.filter(component =>
    !componentList.includes(component) && component !== lookupSerialValue);



  return (
    <ThemeProvider theme={theme}>
      <Stack direction="column" spacing={2} sx={{ border: "solid 1px black ", padding: '30px' }}>
        <Typography variant="h5">Add Components</Typography>
        <form onSubmit={handleSubmit}>
          <Stack direction="row" spacing={2} sx={{ marginTop: '20px' }}>
            <FormControl>
              {/* Lookup Serial Autocomplete */}
              <Autocomplete
              id="part_number"
              value={lookupPartValue}
              onChange={(event, newValue) => {
                setLookupPartValue(newValue);
                setSerialOptions([])
              }}
              onInputChange={(event, newValue) => {
                if (newValue.length > 2) {
                  fetchPartData(newValue, true);
                }
              }}
              options={partNumbers}
              getOptionLabel={(option) => option}
              disableClearable={partNumbers.length === 0} 
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Part Number"
                  variant="outlined"
                  size="small"
                  sx={{ width: "400px" }}
                />
              )}
            />
            <br></br>
              <Autocomplete
                id="serial-lookup"
                options={serialOptions}
                value={lookupSerialValue}
                onChange={(event, newValue) => {
                  setLookupSerialValue(newValue);
                  setComponentList([]);
                  getExistingComponents(newValue)
                }}
                onInputChange={(event, newValue) => {
                  if (newValue.length > 2) {
                    fetchData(newValue, true);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Lookup Serial"
                    variant="outlined"
                    size="small"
                    sx={{ width: '400px' }}
                  />
                )}
              />

              <List>
                <br></br>
                <Typography variant="h5" align="center" >Components List</Typography>
                {componentList !== null && componentList.map((component, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={component} />
                    <IconButton onClick={() => handleRemoveComponent(index)}>
                      <ClearIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </FormControl>
            <Divider orientation="vertical" flexItem />
            {lookupSerialValue && (
              // Select Component Autocomplete
              <Autocomplete
                multiple
                id="component-left"
                options={availableComponents}

                onChange={(event, newValue) => {
                  handleAddComponent(newValue);
                }}
                onInputChange={(event, newValue) => {
                  if (newValue && newValue.length > 2) {
                    fetchComponentData(newValue, false);
                  }
                }}
                filterOptions={(options, { inputValue }) =>
                inputValue.length >= 2
                  ? options.filter((option) =>
                      option.toLowerCase().includes(inputValue.toLowerCase()) &&
                      option !== `${lookupPartValue} | ${lookupSerialValue}` &&
                      !componentList.includes(option)
                    )
                  : []
              }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Component"
                    variant="outlined"
                    size="large"
                    sx={{ width: "400px" }}
                  />
                )}
                renderTags={() => null}
              />
            )}
                <ul>
      { criteria !== null && criteria.map((term, index) => (
        <ListItem1 key={index} term={term} />
      ))}
    </ul>
          </Stack>

          <Button type="submit" variant="contained" sx={{ marginTop: '20px' }}>Submit</Button>

        </form>
      </Stack>

    </ThemeProvider>
  );


}