import ApiMethods from "../ApiMethods";
import ENDPOINTS from "../EndPoints";

class AuthManager {

	static ENDPOINTS = {
		LOGIN: () => `/auth/google`,
		LOGOUT: () => `/auth/logout`,
	};

	static LOGIN = (googleid) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.LOGIN();
		const params = { google_id_token: googleid };

		return ApiMethods.post(url, params);
	};

	static LOGOUT = () => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.LOGOUT();
		const params = {};
		return ApiMethods.post(url, params);
	};
}

export default AuthManager
