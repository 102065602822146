const staleDayCount = 2

/**
 * Calculates the difference between daysAgo and today
 * @param {Number} daysAgo the number of days ago to count to
 * @returns the difference between today and daysAgo not including weekends
 */
const countWeekdays = (daysAgo) => {
    const today = new Date();
    const pastDate = new Date();
    pastDate.setDate(today.getDate() - daysAgo);

    let count = 0;

    for (let d = new Date(pastDate); d <= today; d.setDate(d.getDate() + 1)) {
        const dayOfWeek = d.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) { // 0 is Sunday, 6 is Saturday
            count++;
        }
    }

    return count;
}

/**
 * Determines if lastSeen days ago would be considered stale
 * @param {Number} lastSeen the number of days ago
 * @returns whether to consider it stale or not
 */
const isStale = (lastSeen) => {
    return countWeekdays(lastSeen) > staleDayCount;
}

const staleDays = (lastSeen) => {
    const days = countWeekdays(lastSeen);
    if (days > staleDayCount) {
        return days - staleDayCount
    } else {
        return staleDays
    }
}

const exp = { isStale, staleDays }
export default exp