import { Box, Button, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ApiError from '../../api_utils/ApiError';
import ApiManager from '../../api_utils/ApiManager';
import CustomSnackbar from '../snackbar/CustomSnackbar';
import { GridCheckIcon, GridCloseIcon } from '@mui/x-data-grid';

const ReorderForm = ({ open, onClose, requests = [] }) => {

    const handleApiError = ApiError();

    const [localRequests, setLocalRequests] = useState(requests?.map((request, index) => ({ ...request, index })));
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        setLocalRequests(requests?.map((request, index) => ({ ...request, index })))
    }, [requests])

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(localRequests);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setLocalRequests(items);
    };

    const submitChanges = () => {
        setIsSubmitted(true)
        ApiManager.QUEUE.SETPRIORITY(localRequests.map((request) => (request.id)))
    }

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box
                    sx={{
                        width: 'calc(100vw - 30vmin)',
                        height: 'calc(100vh - 25vmin)',
                        margin: '10vmin',
                        background: 'white',
                        padding: '5vmin',
                        paddingBottom: 0,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div style={{ overflow: 'auto', width: '100%', flex: '1 1 100%' }}>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="localRequests">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {localRequests.map((request, index) => (
                                            <Draggable
                                                key={request.index}
                                                draggableId={request.index.toString()}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                            ...provided.draggableProps.style,

                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            background: '#f0f0f0',
                                                            padding: '0 20px',
                                                            border: '2px solid white'
                                                        }}
                                                    >
                                                        <p>
                                                            {request.part_name} - {request.serial_name}
                                                        </p>
                                                        <p>
                                                            {request.subject} -{' '}
                                                            {new Date(request.request_timestamp).toLocaleString()}
                                                        </p>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                    <Box sx={{
                        width: '100%', display: 'flex', justifyContent: 'center', placeItems: 'center', margin: '4px 0'
                    }}>
                        <Button onClick={onClose} startIcon={<GridCloseIcon />} sx={{color: 'red'}}>
                            QUIT
                        </Button>
                        <Button onClick={submitChanges} startIcon={<GridCheckIcon />} sx={{ color: 'rgb(0, 128, 0)', marginLeft: '16px' }}>
                            SUBMIT
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <CustomSnackbar
                open={isSubmitted}
                onClose={() => setIsSubmitted(false)}
                sx={{
                    border: '2px solid rgb(0, 128, 0)'
                }}
            >
                <div style={{ marginLeft: '16px', width: '100%', justifyContent: 'space-between', display: 'flex', placeItems: 'center', marginRight: '4px' }}>
                    Reordering Submitted
                    <GridCheckIcon />
                </div>
            </CustomSnackbar>
        </>
    );
};

export default ReorderForm;