import ApiMethods from "../ApiMethods";
import ENDPOINTS from "../EndPoints";

class PartManager {
	static ENDPOINTS = {
		PARTS: () => `/parts`,
		GETPARTLIST: () => `/partslist`,
		HISTORY: (part_number, days) =>
			`/parts/${part_number}/history/${days}`,
		CONTRACT_MANUFACTURERS: () => `/contract_manufacturers/`
	};

	static LIST = () => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.GETPARTLIST();
		return ApiMethods.get(url);
	};

	static POSTLIST = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.GETPARTLIST();
		return ApiMethods.post(url, body);
	};

	static LIST_IDS = () => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.PARTS() + "/ids";
		return ApiMethods.get(url);
	};

	static HISTORY = (part_number, days) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.HISTORY(part_number, days);
		return ApiMethods.get(url);
	};

	static ADDCONFIG = (body, pid) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.PARTS() + `/${pid}` + "/config/add";
		return ApiMethods.post(url, body);
	};

	static GETCONFIGS = (pid) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.PARTS() + `/${pid}` + "/config";
		return ApiMethods.post(url);
	};

	static SERIALCONFIGS = (pid) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.PARTS() + `/${pid}` + "/serial-configs";
		return ApiMethods.get(url);
	};

	static CONTRACT_MANUFACTURERS = () => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.CONTRACT_MANUFACTURERS();
		return ApiMethods.get(url)
	}
}

export default PartManager;
