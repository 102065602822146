import React, { useState, useEffect } from 'react';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ApiManager from '../../api_utils/ApiManager';
import ApiError from '../../api_utils/ApiError';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2D5CA9',
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: '#333',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    body2: {
      fontSize: '1rem',
      color: '#555',
    },
    h6: {
      fontWeight: 500,
      fontSize: '1.25rem',
      color: '#2D5CA9',
    },
  },
});

export default function AddSerialForm({ setisSet, handleApiError, onSerialsGenerated }) {
  const [partNumbers, setPartNumberOptions] = useState([]);
  const [lookupPartValue, setLookupPartValue] = useState('');
  const [isError, setisError] = useState('');
  const [mode, setMode] = useState('schema');
  const [cmOptions, setCmOptions] = useState([]);
  const [selectedCm, setSelectedCm] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [serialDetails, setSerialDetails] = useState(null); 
  const [message, setMessage] = useState(''); 
  const [selectedProductCode, setSelectedProductCode] = useState('');

  useEffect(() => {
    if (lookupPartValue && lookupPartValue.length > 2) {
      fetchData(lookupPartValue);
    }
  }, [lookupPartValue]);

  useEffect(() => {
    if (cmOptions.length === 0) {
      setMode('manual');
    }
    if (cmOptions.length > 0) {
      setMode('schema');
    }
  }, [cmOptions]);

  const fetchData = async (value) => {
    const b = { part_number: value };
    const d = await ApiManager.INTAKE.PARTS.SEARCH(b);
    const data = await handleApiError(d);
    setPartNumberOptions([...data]);

    if (data.length > 0) {
      fetchCmOptions(value);
    }
  };

  const fetchCmOptions = async () => {
    const d = await ApiManager.PARTS.CONTRACT_MANUFACTURERS();
    const data = await handleApiError(d);
    setCmOptions([...data]);
  };

  const handlePartNumberChange = (newValue) => {
    if (!newValue) {
        setLookupPartValue('');  // Clear the part value
        setSelectedProductCode('');  // Clear the product code
        return;
    }

    setLookupPartValue(newValue);

    const selectedPart = partNumbers.find((part) => part.name === newValue.name);
    setSelectedProductCode(selectedPart ? selectedPart.product_code : '');
  };

  const getWorkWeek = () => {
    const currentDate = new Date();
    const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
    const pastDaysOfYear = (currentDate - startOfYear + (startOfYear.getDay() ? 6 : 0)) / 86400000;
    return Math.ceil(pastDaysOfYear / 7).toString().padStart(2, '0');
  };

  const generateSchema = () => {
    const currentYear = new Date().getFullYear().toString().slice(-2); // Last two digits of the year
    const currentWeek = getWorkWeek(); // Work week with Monday as the start of the week
    const unit = "XXXXX"; // Placeholder for unit

    if (mode === 'manual') {
      return `${selectedProductCode || '<PC>'}<CM>${currentYear}${currentWeek}${unit}`;
    }

    return `${selectedProductCode || '<PC>'}${selectedCm?.abbreviation || '<CM>'}${currentYear}${currentWeek}${unit}`;
  };

  const validateManualSerial = (serial) => {
    // Step 1: Check if the first 3 characters match the product code
    const productCode = serial.slice(0, 3);
    if (productCode !== selectedProductCode) {
        return false;
    }

    // Step 2: Check if the next 2 characters match any CM abbreviation
    const cmAbbreviation = serial.slice(3, 5);
    const validCM = cmOptions.some(cm => cm.abbreviation === cmAbbreviation);
    if (!validCM) {
        return false;
    }

    // Step 3: Check if the next 4 characters are the correct year and work week
    const yearWorkWeek = serial.slice(5, 9);
    const currentYearWorkWeek = `${new Date().getFullYear().toString().slice(-2)}${getWorkWeek()}`;
    if (yearWorkWeek !== currentYearWorkWeek) {
        return false;
    }

    // Step 4: Check if the last 5 characters are numeric
    const unit = serial.slice(9);
    if (!/^\d{5}$/.test(unit)) {
        return false;
    }

    return true; // If all checks pass, return true
};

  const handleSubmit = async (event) => {
    event.preventDefault();
    setisError("")
  
    const part_input = event.target.part_number.value;
    const serial_input = event.target.serial_number?.value || '';
    const cm_input = selectedCm?.cm_id;
  
    if (!part_input) {
      setisError("Part Number must have an input");
      return;
    }
    if (mode === 'schema' && !cm_input) {
      setisError("Contract Manufacturer must be selected");
      return;
    }
    if (mode === 'manual') {
      if (serial_input.length <= 3) {
        setisError("Serial must at least have 3 characters in manual mode");
        return;
      }
      if (selectedProductCode && !validateManualSerial(serial_input)) {
        setisError("Serial number does not match the required format.");
        return;
      }
    }
  
    let payload;
    let response;
    let data;
  
    if (mode === 'manual') {
      payload = {
        serial_number: serial_input,
        part_number: part_input,
      };
  
      try {
        response = await ApiManager.INTAKE.SERIALS.ADD(payload);
        data = await handleApiError(response);
  
        if (data.message && data.message.startsWith("Error:")) {
          setisError(data.message);
          return;
        }
  
        setSerialDetails(data.serial_details || null);
        setMessage(data.message || 'Operation successful.');
  
        if (onSerialsGenerated) {
          onSerialsGenerated(data);
        }
  
        setisSet(true);
      } catch (error) {
        console.log(error);
        setisError("An error occurred while adding the serial number.");
      }
    } else {
      payload = {
        part_number: part_input,
        serial_number: serial_input,
        cm_id: cm_input,
        mode: mode,
        quantity: quantity,
      };
  
      try {
        response = await ApiManager.INTAKE.SERIALS.GENERATE(payload);
        data = await handleApiError(response);
  
        if (data.message && data.message.startsWith('Error:')) {
          setisError(data.message);
          setSerialDetails(null); // Clear serial details if there's an error
          return;
        }
  
        setSerialDetails(data.serial_details || null);
        setMessage(data.message || 'Operation successful.');
  
        if (onSerialsGenerated) {
          onSerialsGenerated(data);
        }
  
        setisSet(true);
      } catch (error) {
        setisError("An error occurred while generating serials.");
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack direction="column" spacing={3} sx={{ border: 'solid 1px black', padding: '30px' }}>
        <Typography variant="h5" sx={{ mb: 3 }}>Add Serial Number</Typography>
        {isError && <Typography style={{ color: 'red' }} variant="h6">{isError}</Typography>}
        <form onSubmit={handleSubmit}>
          <Stack direction="column" spacing={3}>

            <Select
              value={mode}
              onChange={(e) => setMode(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Mode' }}
              sx={{ width: "400px" }}
            >
              <MenuItem value="schema" disabled={!cmOptions.length}>Schema Mode</MenuItem>
              <MenuItem value="manual">Manual Mode</MenuItem>
            </Select>

            <Autocomplete
              id="part_number"
              value={lookupPartValue}
              onChange={(event, newValue) => handlePartNumberChange(newValue)}
              onInputChange={(event, newValue) => {
                if (newValue.length > 2) {
                  fetchData(newValue);
                }
              }}
              options={partNumbers}
              getOptionLabel={(option) => option?.name || ''}
              disableClearable={partNumbers.length === 0}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Part Number"
                  variant="outlined"
                  size="large"
                  sx={{ width: "400px" }}
                />
              )}
            />

            <Card variant="outlined" sx={{ marginTop: '20px', padding: '20px', backgroundColor: '#f9f9f9' }}>
              <CardContent>
                {selectedProductCode && (
                  <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Product Code: {selectedProductCode}
                  </Typography>
                )}

                {mode === 'schema' && selectedCm && (
                  <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Contract Manufacturer Abbreviation: {selectedCm.abbreviation}
                  </Typography>
                )}

                <Grid container spacing={2} sx={{ marginTop: '10px' }}>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Year: {new Date().getFullYear().toString().slice(-2)}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'right' }}>Work Week: {getWorkWeek()}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Unit: XXXXX</Typography>
                  </Grid>
                </Grid>

                <Typography variant="h6" sx={{ marginTop: '20px', textAlign: 'center', fontWeight: 'bold', color: theme.palette.primary.main }}>
                  Template Schema: {generateSchema()}
                </Typography>
              </CardContent>
            </Card>

            {mode === 'schema' && (
              <Autocomplete
                id="cm_id"
                value={selectedCm}
                onChange={(event, newValue) => {
                  setSelectedCm(newValue);
                }}
                options={cmOptions}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Contract Manufacturer"
                    variant="outlined"
                    size="large"
                    sx={{ width: "400px" }}
                  />
                )}
              />
            )}

            {mode === 'manual' && (
              <TextField
                id="serial_number"
                label="Serial Number"
                variant="outlined"
                fullWidth
                disabled={mode === 'schema'}
              />
            )}

            {mode !== 'manual' && (
              <TextField
                id="quantity"
                label="Quantity"
                variant="outlined"
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                fullWidth
                sx={{ marginTop: '20px' }}
              />
            )}

            <Button type="submit" variant="contained" sx={{ marginTop: '20px', fontWeight: 'bold', fontSize: '1rem', padding: '10px 20px' }}>
              Submit
            </Button>
          </Stack>
        </form>
      </Stack>
    </ThemeProvider>
  );
}
