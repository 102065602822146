import ApiMethods from "../ApiMethods";
import ENDPOINTS from "../EndPoints";

class MacManager {
	static ENDPOINTS = {
		ASSIGNED: () => `/mac/assigned`,
		UTILIZATION: () => `/mac/utilization`,
		SERIAL: (part_number, serial_number) =>
			`/parts/${part_number}/serials/${serial_number}/mac`,
	};

	static ASSIGNED = () => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.ASSIGNED();
		return ApiMethods.get(url);
	};

	static UTILIZATION = () => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.UTILIZATION();
		return ApiMethods.get(url);
	};

	static SERIAL = (partNumber, serialNumber) => {
		const url =
			ENDPOINTS.BASE_URL + this.ENDPOINTS.SERIAL(partNumber, serialNumber);
		return ApiMethods.get(url);
	};
}

export default MacManager;
