import React from 'react'

import { convertCommentToReact } from './CommentToReact';
import { Avatar, Box, Tooltip, Typography } from '@mui/material';
import FileDisplay from './FileDisplay';

/**
 * A fancy comment CSS that aligns the comment to the left and right of the container depending on who sent it
 * @param {String} comment a comment in DraftJS format
 * @param {Boolean} left whether the comment should appear on the left or right of the container
 * @param {String} author the author of the comment
 * @param {String} timestamp the timestamp (to be converted with time formatting)
 * @param {Object} files an object of files containing the information about them including the url. The file name is the key 
 * @returns the comment
 */
const Comment = ({ comment, left, author, timeStamp, files }) => {

    const reactElements = convertCommentToReact(comment);

    /**
     * Handles the time formatting to: MM/DD/YYYY - HH:MM AM/PM
     * @param {String} timeStamp 
     * @returns 
     */
    const timeFormatter = (timeStamp) => {
        const date = new Date(timeStamp);
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} - ${hours}:${minutes} ${ampm}`;
    }

    return (
        <div style={{ display: 'flex', placeContent: left ? 'start' : 'end', marginBottom: '1rem', width: '100%' }}>
            <Box sx={{ height: 'fit-content', display: 'flex', position: 'relative', width: 'fit-content', maxWidth: '70%' }}>
                <div
                    style={{
                        width: '2rem',
                        height: '2rem',
                        left: left ? '1.5rem' : 'auto',
                        right: left ? 'auto' : '1.5rem',
                        bottom: '1.25rem',
                        background: left ? 'gray' : '#0a9dd9',
                        position: 'absolute'
                    }}
                />
                <Tooltip title={author || "Anonymous"}>
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '1.25rem',
                            left: left ? '0' : 'auto',
                            right: left ? 'auto' : '0',
                            background: 'white',
                            borderRadius: '9999px'
                        }}
                    >
                        <Avatar sx={{ bgcolor: left ? 'gray' : '#0a9dd9', margin: '8px' }}>
                            {author && (
                                author.length === 1 ? author.toUpperCase() : author.split(' ').map(n => n[0]).join('').toUpperCase()
                            )}
                        </Avatar>
                    </div>
                </Tooltip>
                <div>
                    <Box
                        sx={{
                            marginLeft: left ? '3.5rem' : '0',
                            marginRight: left ? '0' : '3.5rem',
                            borderRadius: '1rem',
                            borderBottomLeftRadius: left ? '0' : '1rem',
                            borderBottomRightRadius: left ? '1rem' : '0',
                            flex: 1,
                            background: left ? 'gray' : '#0a9dd9',
                            justifyContent: 'center',
                            placeItems: 'center',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Typography variant='body1' sx={{ color: 'white', minHeight: '2rem', paddingLeft: '1rem', paddingRight: '1rem' }}>
                            {reactElements || 'No content to display'}
                        </Typography>
                        <FileDisplay files={files} color={"white"} />
                    </Box>
                    <div style={{ textAlign: left ? 'left' : 'right', textWrap: 'nowrap', paddingRight: left ? 'auto' : '2rem', paddingLeft: left ? '2rem' : 'auto' }}>
                        <Typography variant='body2'>
                            {timeFormatter(timeStamp)}
                        </Typography>
                    </div>
                </div>
            </Box>

        </div>
    );
};

export default Comment