import ApiMethods from "../ApiMethods";
import ENDPOINTS from "../EndPoints";

class ECOManager {

	static ENDPOINTS = {
		GETDETAIL: () => `/eco`,
		GETECOBYPARTNUM: () => `/eco/part_number`,
	};

	static DETAIL_BYPARTNUM = (params) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.GETECOBYPARTNUM() + `/${params}`;
		return ApiMethods.get(url);
	};

	static PARTSLIST = () => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.PARTS.LIST();
		return ApiMethods.get(url);
	};

	static DETAIL = () => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.GETDETAIL();
		return ApiMethods.get(url);
	};
}

export default ECOManager
