import React, { useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button';
import { CircularProgress, Grid, IconButton, LinearProgress, Tooltip, Typography } from '@mui/material';
import { FileUploadForm } from '../.'
import Box from '@mui/material/Box';
import MUIRichTextEditor from 'mui-rte'
import { convertToRaw, EditorState, convertFromRaw } from 'draft-js'
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { loginFailure } from '../../store/auth/authStateSlice';

import ApiManager from '../../api_utils/ApiManager';
import ApiError from '../../api_utils/ApiError';
import HoldButton from '../holdbutton/HoldButton';
import AddIcon from '@mui/icons-material/Add';
import Comment from '../comment/Comment';
import { convertCommentToReact } from '../comment/CommentToReact';
import FileDisplay from '../comment/FileDisplay';
import { GridCheckIcon, GridCloseIcon } from '@mui/x-data-grid';
import CustomSnackbar from '../snackbar/CustomSnackbar';
import WarningIcon from '@mui/icons-material/Warning';
import JiraIcon from '../../icons/JiraIcon';
import { Link } from 'react-router-dom';


/**
 * The queue form, which houses comments, subject, description, timestamps and any relevant data to the ECO request. It also has the option to comment, submit or cancel the request
 * @param {Object} data the data from the ECO request
 * @param {Function} applyFilter applies the front end filter
 * @param {Function} setOpenForm sets whether the form is open
 * @param {Object} currentFile the files in object form, where the key is the file name
 * @param {Array} comments the comments
 * @param {Function} setComments set comments (used when adding comments)
 * @param {Function} onClose the on close function
 * @param {Function} setSentSubmit a function from a useState for Submitting
 * @param {Function} setSentCancel a function from a useState for Cancelling the ECO Request
 * @param {Boolean} isLoadingComments a boolean of whether comments are being fetched
 * @returns the Queue Frorm
 */
export default function QueueForm({ data, applyFilter, setOpenForm, currentFile, comments, setComments, onClose, setSentSubmit, setSentCancel, isLoadingComments }) {

    const style = {
        position: 'absolute',
        borderRadius: '1rem',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        height: "180px",
        bgcolor: 'white',
        overflowY: 'auto',
        '&::-webkit-scrollbar': { width: 0, }
    };

    const style_cancel = {
        position: 'absolute',
        borderRadius: '25px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        height: "350px",
        bgcolor: 'white',
        overflowY: 'auto',
        paddingLeft: "10px",
        '&::-webkit-scrollbar': { width: 0, }
    };

    const authData = useSelector((state) => state.auth);
    const { user } = authData;

    const [richText, setRichText] = useState(null)
    const [error, setError] = useState('')
    const [errorPU, setErrorPU] = useState('')
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [reason, setReason] = useState('');
    const [otherDetails, setOtherDetails] = useState('');

    const [openConfirm, setOpenConfirm] = useState(false);
    const [openCancel, setOpenCancel] = useState(false);
    const [defaultRichText, setDefaultRichText] = useState('{"blocks": [{"key":"7po5","type": "text", "text": ""}], "entityMap": {}}')
    const [richTextKey, setRichTextKey] = useState(false)
    const [sentComment, setSentComment] = useState(false)
    const [isSendingComment, setIsSendingComment] = useState(false)

    const [resetFile, setResetFile] = useState(false)
    const [emptyRichText, setemptyRichText] = useState(true);

    const subRef = useRef(undefined)
    const rteRef = useRef(undefined)
    const buttonRef = useRef(undefined)
    const formRef = useRef(undefined)
    const [buttonWidths, setButtonWidths] = useState(0)
    const [subWidth, setSubWidth] = useState(1)

    const dispatch = useDispatch()
    const handleApiError = ApiError()
    const handleFilesSelected = (selectedFiles) => {
        setSelectedFiles(selectedFiles)
    };

    /**
     * Closes the confirmation windows
     */
    const handleCloseRequest = () => {
        setOpenConfirm(false)
        setOpenCancel(false)
    }

    /**
     * handles comments being added (includes loading logic)
     * @param {Event} e the event from the button press
     */
    const handleComments = async (e) => {
        e.preventDefault();

        setResetFile(false);

        const formData = new FormData();
        formData.append('author', user);
        formData.append('content', richText);
        formData.append('request_id', data.id);
        formData.append('serial_number', data.serial_name);

        // If no files are selected, append a placeholder file
        if (selectedFiles.length === 0) {
            formData.append('upload_files', new File([], 'placeholder'));
        } else {
            // Append selected files
            selectedFiles.forEach(file => formData.append('upload_files', file));
        }

        setIsSendingComment(true)
        ApiManager.QUEUE.ADDCOMMENT(formData)
            .then(handleApiError)
            .then((dataResponse) => {
                console.log(dataResponse['message']);
                if (dataResponse['message'].trim() === 'Successfully Created Comment') {
                    const obj2 = {
                        request_id: data.id,
                        serial_number: data.serial_name
                    };

                    ApiManager.QUEUE.GETREQUESTCOMMENT(obj2)
                        .then(handleApiError)
                        .then((commentDataResponse) => {
                            console.log('comment response', commentDataResponse);
                            if (Array.isArray(commentDataResponse)) {
                                setComments(commentDataResponse);
                                setSentComment(true);
                                setRichText(null)

                                // Reset rich text editor state after successfully posting a comment
                                setDefaultRichText('{"blocks": [{"key":"7po5","type": "text", "text": ""}], "entityMap": {}}');
                                setRichTextKey(i => !i)
                                setResetFile(true);
                            }
                            setIsSendingComment(false)
                        })
                }
            })
            .catch((error) => console.error("Error occurred while adding a comment:", error))

    };

    /**
     * Confirms submission
     */
    const handleConfirm = () => {
        setOpenConfirm(false);
        handleSubmit2();
    }

    /**
     * Handles the form submission (comment, submit or cancel all lead here)
     * @param {Event} e the event
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const command = e.nativeEvent.submitter.name
        if (user.length !== 0) {
            if (command === "cancel") {
                setOpenCancel(true)
                return
            }
        }
        if (user.length === 0) {
            setError('Must be logged in')
            return
        }
        if (emptyRichText) {
            setError('Text cannot be empty')
            scrollToRichTextEditor()
            return
        }

        if (command === "comment") {
            handleComments(e)
        } else {
            setOpenConfirm(true)
        }
    }

    /**
     * Handles cancelling the request by opening a window
     */
    const handleCancel = () => {

        const canceledBlock = {
            key: 'cancelled',
            text: 'CANCELED',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        };
        const contentState = {
            entityMap: {},
            blocks: [canceledBlock],
        };

        const editorState = EditorState.createWithContent(convertFromRaw(contentState));

        if (user.length === 0) {
            setError('Input cannot be empty')
            return
        }
        else if (reason === "Select a Reason" || reason === "") {
            setErrorPU('Provide a reason')
            console.log("Here")
            return
        }
        else if (reason === "Other" && !otherDetails.trim()) {
            setErrorPU('Please provide details for the "Other" reason');
            console.log("Details required for 'Other'");
            return;
        } else {
            setError('')
            console.log(reason, otherDetails)
            let formData = new FormData();
            formData.append('fixer', user);
            formData.append('comments', JSON.stringify(convertToRaw(editorState.getCurrentContent())));
            formData.append('request_id', data.id);
            formData.append('serial_id', data.sid);
            formData.append('serial_number', data.serial_name);
            formData.append('isCancel', true)

            if (reason === 'Other') {
                formData.append('reason', otherDetails);
            } else {
                formData.append('reason', reason);
            }

            if (selectedFiles.length === 0) {
                formData.append('upload_files', new File([], 'placeholder'));
            }
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append('upload_files', selectedFiles[i]);
            }

            ApiManager.QUEUE.ADDFIXER(formData)
                .then(handleApiError)
                .then(() => {
                    setSentCancel(true);
                    setRichText(null)

                    setTimeout(() => {
                        setOpenForm(false);
                    }, 1000);
                })
                .catch((error) => dispatch(loginFailure(error)))


            applyFilter()
        }

    }

    /**
     * Handles the submission of the ECO request
     */
    const handleSubmit2 = async () => {

        if (user.length === 0 || emptyRichText) {
            setError('Input cannot be empty')
        }
        else {
            setError('')

            let formData = new FormData();
            formData.append('fixer', user);
            formData.append('comments', richText);
            formData.append('request_id', data.id);
            formData.append('serial_id', data.sid);
            formData.append('serial_number', data.serial_name);
            formData.append('isCancel', false)
            formData.append("reason", "None")
            if (selectedFiles.length === 0) {
                formData.append('upload_files', new File([], 'placeholder'));
            }
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append('upload_files', selectedFiles[i]);
            }

            ApiManager.QUEUE.ADDFIX(formData)
                .then(handleApiError)
                .then(() => {
                    setSentSubmit(true);
                    setRichText(null)

                    setTimeout(() => {
                        setOpenForm(false);
                    }, 1000);
                    applyFilter();
                })
        }
    }

    /**
     * Handles the time formatting to: MM/DD/YYYY - HH:MM AM/PM
     * @param {String} timeStamp 
     * @returns 
     */
    const timeFormatter = (timeStamp) => {
        const date = new Date(timeStamp);
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} - ${hours}:${minutes} ${ampm}`;
    }

    /**
     * Scrolls to the rich text editor (used when the error is caused by having no content in the RTE)
     */
    const scrollToRichTextEditor = () => {
        if (rteRef.current && formRef.current) {
            formRef.current.scrollTo({
                top: rteRef.current.offsetTop - formRef.current.offsetTop,
                behavior: 'smooth'
            });
        }
    };

    /**
     * Use effect used for centering the part number and serial buttons
     */
    useEffect(() => {
        const updateWidths = () => {
            if (buttonRef.current) {
                setButtonWidths(buttonRef.current.getBoundingClientRect().width)
            }

            if (subRef.current) {
                setSubWidth(subRef.current.getBoundingClientRect().width)
            }
        }

        updateWidths()

        window.addEventListener('resize', updateWidths)

        return () => window.removeEventListener('resize', updateWidths)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buttonRef.current, subRef.current])

    /**
     * Use effect used for resetting values when unmounting
     */
    useEffect(() => {
        return () => {
            setError("");
            setSelectedFiles([]);
            setRichText("");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box ref={formRef} sx={{ width: 'calc(100vw - 8rem)', height: 'calc(100vh - 8rem)', padding: '1rem', margin: '4rem', background: 'white', borderRadius: '1rem', position: 'relative', overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
            {openConfirm && (
                <Modal
                    open={openConfirm}
                    onClose={handleCloseRequest}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="modal-container" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: "#2D5CA9", borderTopRightRadius: '1rem' }}>
                                <Typography id="modal-modal-title" variant="h6" component="h2" style={{ flex: 1 }}>
                                    Confirmation
                                </Typography>
                            </div>
                            <div style={{ fontSize: '22px', marginLeft: '8px' }}>Do you wish to mark this ticket as resolved?</div>
                            <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Button variant="contained" style={{ margin: '10px', color: 'white', backgroundColor: '#0275d8' }} onClick={handleConfirm}>Confirm</Button>
                                <Button onClick={() => setOpenConfirm(false)}>Cancel</Button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            )}
            {openCancel && (
                <Modal
                    open={openCancel}
                    onClose={handleCloseRequest}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style_cancel}>

                        <div className="modal-container" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: "#2D5CA9", borderTopRightRadius: '25px' }}>
                                <Typography id="modal-modal-title" variant="h6" component="h2" style={{ flex: 1 }}>
                                    Canceling
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', placeItems: 'center', height: '100%', flexDirection: 'column' }}>
                                {errorPU && <div style={{ "color": "red", fontSize: "24px", marginLeft: "20px", textAlign: 'center' }}>Error: {errorPU}</div>}
                                <div style={{ fontSize: '22px', marginLeft: '20px', textAlign: 'center' }}>Please Provide a Reason</div>

                                {/* Dropdown for Reason */}
                                <select value={reason} onChange={e => setReason(e.target.value)} style={{ margin: '20px', padding: '10px', width: 'calc(100% - 40px)' }}>
                                    <option value="">Select a Reason</option>
                                    <option value="Unit Unavailable">Unit Unavailable</option>
                                    <option value="Matierl Unavailable">Matierl Unavailable</option>
                                    <option value="Request No Longer Necessary">Request No Longer Necessary</option>
                                    <option value="Accidental Entry">Accidental Entry</option>
                                    <option value="Other">Other</option>
                                </select>

                                {/* Conditional Text Field for "Other" reason */}
                                {reason === 'Other' && (
                                    <textarea
                                        value={otherDetails}
                                        onChange={e => setOtherDetails(e.target.value)}
                                        placeholder="Provide details..."
                                        style={{ width: '90%', padding: '10px', marginTop: '10px', marginLeft: "20px" }}
                                    />
                                )}

                                <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Button variant="contained" style={{ margin: '10px', color: 'white', backgroundColor: '#0275d8' }} onClick={handleCancel}>Confirm</Button>
                                    <Button onClick={() => setOpenCancel(false)}>Cancel</Button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>
            )}
            <Box sx={{ width: 'calc(100% - 2rem)', padding: '1rem 1rem', background: '#2d5ca9', borderRadius: '.5rem', position: 'relative', display: 'flex', justifyContent: 'space-between', placeItems: 'center' }}>
                {data.jira_ticket ? <Link to={data.jira_ticket}>
                    <Tooltip title="Jira Ticket" slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -14],
                                    },
                                },
                            ],
                        },
                    }}>
                        <IconButton sx={{ color: 'white', position: 'relative' }}>
                            <JiraIcon />
                        </IconButton>
                    </Tooltip>
                </Link> : <div />}
                <Typography variant='h5' sx={{ color: 'white', textAlign: 'center', }}>{data.subject}</Typography>
                <IconButton onClick={onClose} >
                    <GridCloseIcon />
                </IconButton>
            </Box>
            <Box sx={{ width: '95%', padding: '.5rem 0', margin: '0 2.5%', flex: 1, display: 'flex', flexDirection: 'column' }}>
                <Box ref={subRef} sx={{ borderBottom: '2px solid #2d5ca9', display: 'flex', position: 'relative', justifyContent: 'center' }}>
                    <Box ref={buttonRef} sx={{ position: 'absolute', left: (subWidth - buttonWidths) / 2, width: 'fit-content', display: 'flex' }}>
                        <HoldButton params={{ value: data.part_name }} url={`/part?partNum=${data.part_name}`} color={'#2d5ca9'}>
                            <span style={{ whiteSpace: 'nowrap' }}>{data.part_name}</span>
                        </HoldButton>
                        <HoldButton params={{ value: data.serial_name }} url={`/log/${data.serial_name}?sid=${data.sid}`} color={'#2d5ca9'}>
                            <span style={{ whiteSpace: 'nowrap' }}>{data.serial_name}</span>
                        </HoldButton>
                    </Box>
                    <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant='body' sx={{ width: 'calc(100% - 1rem)', marginLeft: '1rem', padding: '.75rem 0' }}>
                            {data.requester}
                        </Typography>
                    </Box>
                    <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant='body' sx={{ width: 'calc(100% - 1rem)', marginRight: '1rem', textAlign: 'right' }}>
                            {timeFormatter(data.request_timestamp)}
                        </Typography>
                    </Box>
                </Box>

                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} sx={{ height: '50vh', margin: '.5rem' }}>
                        <Grid item xs={6} sx={{ paddingTop: '1rem', height: '100%' }}>
                            <Box sx={{ width: '100%', height: 'calc(100% - 32px)', overflowY: 'auto', display: 'flex', flexDirection: 'column-reverse' }}>
                                {isLoadingComments ? (
                                    <div style={{ height: '100%', width: '100%', justifyContent: 'center', placeItems: 'center', display: 'flex' }}>
                                        <CircularProgress />
                                    </div>
                                ) : (comments && comments.length > 0) ?
                                    comments.sort((a, b) => a.comment_timestamp - b.comment_timestamp).map((comment) => (

                                        <Comment comment={comment.content} timeStamp={comment.comment_timestamp} author={comment.author} files={comment.comment_files} left={comment.author !== user} />
                                    )) : (
                                        <div style={{ height: '100%', width: '100%', justifyContent: 'center', placeItems: 'center', display: 'flex' }}>
                                            No Comments.
                                        </div>
                                    )
                                }
                            </Box>
                            <Button
                                type='submit'
                                name='comment'
                                startIcon={<AddIcon />}
                                sx={{
                                    width: '100%',
                                    height: '32px',
                                    color: '#fff', // Text color (white)
                                    backgroundColor: '#2d5ca9', // Base background color
                                    '&:hover': {
                                        backgroundColor: '#1e4a8a', // Darker blue on hover
                                    },
                                    borderRadius: '4px', // Rounded corners
                                    boxShadow: 'none', // Remove default shadow
                                    textTransform: 'none', // Preserve text case
                                }}
                            >
                                Add Comment
                            </Button>

                        </Grid>
                        <Grid item xs={6} sx={{ paddingTop: '1rem', height: '100%' }}>
                            <Box sx={{ width: '100%', height: 'calc(100% - 32px)', overflowY: 'auto' }}>
                                {convertCommentToReact(data.task)}
                                <FileDisplay files={currentFile} displayName={true} />
                            </Box>
                            <Box sx={{ height: '32px', display: 'flex', width: 'calc(100% - 3rem)' }}>
                                <Button
                                    variant='contained'
                                    type='submit'
                                    name='submit'
                                    startIcon={<GridCheckIcon />}
                                    sx={{
                                        flex: 1,
                                        height: '32px',
                                        color: '#fff', // Text color (white)
                                        backgroundColor: '#28a745', // Background color (green)
                                        '&:hover': {
                                            backgroundColor: '#218838', // Darker green on hover
                                        },
                                        borderRadius: '4px', // Rounded corners
                                        boxShadow: 'none', // Remove default shadow
                                        textTransform: 'none', // Preserve text case,
                                        marginRight: '.25rem'
                                    }}
                                >
                                    Submit
                                </Button>

                                <Button
                                    type='submit'
                                    name='cancel'
                                    startIcon={<GridCloseIcon />}
                                    sx={{
                                        flex: 1,
                                        height: '32px',
                                        color: '#fff', // Text color (white)
                                        backgroundColor: '#dc3545', // Background color (red)
                                        '&:hover': {
                                            backgroundColor: '#c82333', // Darker red on hover
                                        },
                                        borderRadius: '4px', // Rounded corners
                                        boxShadow: 'none', // Remove default shadow
                                        textTransform: 'none', // Preserve text case
                                        marginLeft: '.25rem'
                                    }}
                                >
                                    Cancel ECO Request
                                </Button>

                            </Box>
                        </Grid>
                    </Grid>
                    <FileUploadForm
                        id="outlined-multiline-static"
                        label="uploads"
                        name="uploads"
                        onFilesSelected={handleFilesSelected}
                        resetFile={resetFile}
                    />
                    <CustomSnackbar
                        open={!!error}
                        onClose={() => setError(null)}
                        sx={{ border: '2px solid red' }}
                    >
                        <span>Error: {error}</span>
                        <WarningIcon sx={{ marginLeft: '.5rem' }} />
                    </CustomSnackbar>

                    <CustomSnackbar
                        open={sentComment}
                        onClose={() => setSentComment(false)}
                        sx={{ border: '2px solid rgb(0, 128, 0)' }}
                    >
                        <span>Comment Sent Successfully</span>
                        <GridCheckIcon sx={{ marginLeft: '.5rem' }} />
                    </CustomSnackbar>

                    <Box ref={rteRef} sx={{ border: '1px solid lightgray', height: '100%', borderRadius: '.25rem', marginTop: '.5rem', outline: error === 'Text cannot be empty' ? '2px solid red' : 'none', transition: 'ease-in-out 300ms all', position: 'relative' }}>
                        {isSendingComment ? (
                            <LinearProgress sx={{ height: '5px' }} />
                        ) : (
                            <div style={{ height: '5px' }} />
                        )}
                        <MUIRichTextEditor label="Start typing comments"
                            defaultValue={defaultRichText}
                            key={richTextKey}
                            onChange={(e) => {
                                const rteContent = convertToRaw(e.getCurrentContent()) // for rte content with text formating
                                rteContent && setRichText(JSON.stringify(rteContent))
                                const contentState = e.getCurrentContent()
                                const hasContent = contentState.hasText()

                                if (!hasContent) {
                                    setemptyRichText(true)
                                } else {
                                    setemptyRichText(false)
                                }
                            }}
                        />
                    </Box>
                </form>
            </Box >
        </Box >

    )
}
