import ApiMethods from "../ApiMethods";
import ENDPOINTS from "../EndPoints";

class BuildManager {

	static ENDPOINTS = {
		BUILDS: () => `/build-parts`,
	};

	static GET = () => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.BUILDS()
		return ApiMethods.get(url)
	}
}

export default BuildManager
