
import React from 'react'
import { Modal, Typography, Box, Divider, Stack } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const TemplateModal = (props) => {

    const { openTest, displayOpenTest, handleCloseTest, testResult, displayDataTest } = props


    console.log("props", props)

    if (openTest && displayOpenTest)
        return (
            <div>
                <Typography gutterBottom variant="body1">
                    <Modal
                        open={displayOpenTest}
                        onClose={handleCloseTest}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div className="modal-container" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                                <div style={{ display: 'flex', backgroundColor: "#2D5CA9", borderTopRightRadius: '25px' }}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ flex: 1 }}>
                                        {testResult.test_name}
                                    </Typography>
                                    <button id='cancel' className="cancel-style" onClick={handleCloseTest}> <ClearIcon /> </button>
                                </div>
                                {testResult.result.includes("PASS") &&
                                    <Typography id="modal-modal-status-pass" variant="h6" component="h2">
                                        {testResult.result}

                                    </Typography>
                                }
                                {!testResult.result.includes("PASS") &&
                                    <Typography id="modal-modal-status-fail" variant="h6" component="h2">
                                        {testResult.result}

                                    </Typography>
                                }

                                <Stack direction="row" spacing={.3}>
                                    <Box id="modal-list-container">
                                        <Typography id="modal-list-title" variant="h6" component="h2">
                                            Limits
                                        </Typography>
                                        <Stack spacing={1}>
                                            <Typography id="modal-list-li" variant="h6" component="h2">
                                                <span><strong>Upper Limit:</strong></span>
                                                <span>{displayDataTest.upperbound}</span>
                                            </Typography>

                                            <Typography id="modal-list-li" variant="h6" component="h2">
                                                <strong>Lower Limit:</strong> {displayDataTest.lowerbound}
                                            </Typography>
                                            <Typography id="modal-list-li" variant="h6" component="h2">
                                                <strong>Eridan Upper Limit:</strong> {displayDataTest.eridan_upperbound}
                                            </Typography>
                                            <Typography id="modal-list-li" variant="h6" component="h2">
                                                <strong>Eridan Lower Limit:</strong> {displayDataTest.eridan_lowerbound}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                    <Box id="modal-list-container" >
                                        <Typography id="modal-list-title" variant="h6" component="h2">
                                            Metrics
                                        </Typography>
                                        <Stack spacing={1}>
                                            <Typography id="modal-list-li" variant="h6" component="h2">
                                                <strong>Measurement:</strong> {displayDataTest.measurement}
                                            </Typography>
                                            <Typography id="modal-list-li" variant="h6" component="h2">
                                                <strong>Units:</strong> {displayDataTest.units}
                                            </Typography>

                                        </Stack>


                                    </Box>
                                    <Box id="modal-list-container" >
                                        <Typography id="modal-list-title" variant="h6" component="h2">
                                            Computer Details
                                        </Typography>
                                        <Stack spacing={1}>
                                            <Typography id="modal-list-li" variant="h6" component="h2">
                                                <span><strong>Software Version:</strong></span>
                                                <span>{displayDataTest.software_version}</span>
                                            </Typography>
                                            <Typography id="modal-list-li" variant="h6" component="h2">
                                                <span><strong>Machine:</strong></span>
                                                <span>{displayDataTest.machine}</span>
                                            </Typography>
                                            <Typography id="modal-list-li" variant="h6" component="h2">
                                                <span><strong>User:</strong></span>
                                                <span>{displayDataTest.user}</span>
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </Stack>
                                <div id="modal-modal-description" sx={{ mt: 2 }} style={{ marginTop: '0', width: '100%', minHeight: '45%' }}>
                                    <strong>Comments: </strong>
                                    <pre>
                                        {displayDataTest.comment}

                                    </pre>
                                </div>
                            </div>


                        </Box>

                    </Modal>
                </Typography>
                <Divider variant="middle" />

            </div>


        )
    else
        return null
}

export default TemplateModal