import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Button, TextField, IconButton, Typography, FormControl, Box, Autocomplete } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ApiManager from '../../api_utils/ApiManager';
import ApiError from '../../api_utils/ApiError';
import { useSelector } from 'react-redux';
import Table from '../../components/table/Table';

function Configurations() {

  const useQuery = () => {
    return React.useMemo(() => {
      const params = new URLSearchParams(window.location.search);
      const queryObject = {};

      params.forEach((value, key) => {
        queryObject[key] = value;
      });

      return queryObject;
    }, [window.location.search]);
  };

  const queryObject = useQuery();

  const [parts, setParts] = useState([]);
  const [currentPartNumber, setCurrentPartNumber] = useState(null);

  const [serials, setSerials] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedSerial, setSelectedSerial] = useState(null);
  const [editedDeviationConfig, setEditedDeviationConfig] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [bulkUpdateValue, setBulkUpdateValue] = useState('');
  const [configToUpdate, setConfigToUpdate] = useState('');
  const [editableConfigs, setEditableConfigs] = useState([]);
  const [logs, setLogs] = useState([]);
  const logsRef = useRef(null);

  const authData = useSelector((state) => state.auth);
  const { user } = authData;

  const handleApiError = ApiError();

  const onSelectionModelChange = (newSelectionModel) => {
    setSelectedRows(newSelectionModel);
  };

  const logColumns = [
    { field: 'log_id', headerName: 'Log ID', flex: 0.125, width: 100 },
    { field: 'config_name', headerName: 'Name', flex: 0.125, width: 100 },
    { field: 'sid', headerName: 'Serial ID', flex: 0.125, width: 100 },
    { field: 'old_value', headerName: 'Old Value', flex: 0.125, width: 150 },
    { field: 'new_value', headerName: 'New Value', flex: 0.125, width: 150 },
    { field: 'change_type', headerName: 'Change Type', flex: 0.125, width: 150 },
    { field: 'changed_by', headerName: 'Changed By', flex: 0.125, width: 150 },
    { field: 'change_timestamp', headerName: 'Change Timestamp', flex: 0.25, width: 200 },
  ];

  useEffect(() => {
    (async () => {
      try {
        const response = await ApiManager.PARTS.LIST_IDS();
        const responseData = await handleApiError(response);
        if (response.error) {
          console.error('API Error:', response.error);
          return;
        }
        console.log("sss", responseData);
        const newParts = responseData.map(part => ({ id: part.pid, name: part.Name }))
        setParts(newParts);
        setCurrentPartNumber(newParts.find(part => part.name === queryObject.partNumber)?.id)
      } catch (error) {
        console.error('Failed to fetch parts:', error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentPartNumber) {
      (async () => {
        try {
          const response = await ApiManager.PARTS.GETCONFIGS(currentPartNumber);
          const responseData = await handleApiError(response);
          setEditedDeviationConfig(responseData.map(config => config.config_name));
        } catch (error) {
          console.error('Failed to fetch configurations:', error);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPartNumber]);

  const getConfigColumns = async () => {
    (async () => {
      try {
        const response = await ApiManager.PARTS.SERIALCONFIGS(currentPartNumber);
        const responseData = await handleApiError(response);
        if (responseData && responseData.length > 0) {
          setSerials(responseData.map(item => ({ ...item, id: item.sid })));

          const dynamicColumns = Object.keys(responseData[0]).map(key => ({
            field: key,
            headerName: key.charAt(0).toUpperCase() + key.slice(1),
            flex: 1 / Object.keys(responseData[0]).length,
            editable: !['sid', 'name'].includes(key)
          }));

          setColumns(dynamicColumns);
          setEditableConfigs(dynamicColumns.filter(col => col.editable).map(col => col.field));
        } else {
          setSerials([]);
          setColumns([]);
          setEditableConfigs([]);
        }
      } catch (error) {
        console.error('Failed to fetch serial configurations:', error);
      }
    })();
  }

  useEffect(() => {
    if (currentPartNumber) {
      getConfigColumns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPartNumber]);

  const fetchLogs = async (sid) => {
    try {
      const response = await ApiManager.SERIALS.CONFIG_LOGS(sid); // Placeholder function
      const responseData = await handleApiError(response);
      setLogs(responseData);
      logsRef.current.scrollIntoView({ behavior: 'smooth' }); // Scroll to the logs section
    } catch (error) {
      console.error('Failed to fetch logs:', error);
    }
  };

  const fetchSerialConfigurations = async () => {
    try {
      const response = await ApiManager.PARTS.SERIALCONFIGS(currentPartNumber);
      const responseData = await handleApiError(response);
      console.log("serials", responseData);
      setSerials(responseData.map(item => ({ ...item, id: item.sid })));

      let dynamicColumns = Object.keys(responseData[0] || {}).map(key => ({
        field: key,
        headerName: key.charAt(0).toUpperCase() + key.slice(1),
        width: 225,
        editable: !['sid', 'name'].includes(key)
      }));

      dynamicColumns = dynamicColumns.sort((a, b) => {
        if (a.field === 'sid') return -2;
        if (b.field === 'sid') return 2;
        if (a.field === 'name') return -1;
        if (b.field === 'name') return 1;
        return 0;
      });

      setColumns(dynamicColumns);
    } catch (error) {
      console.error('Failed to fetch serial configurations:', error);
    }
  };

  const onRowClick = (params) => {
    const selectedSid = params.row.sid;
    setSelectedSerial(selectedSid); // Store the selected serial ID
    fetchLogs(selectedSid);
  };

  const handleBulkUpdate = async () => {
    console.log("SELECTED:", selectedRows);
    if (!configToUpdate || !bulkUpdateValue || selectedRows.length === 0) {
      alert("Please select rows and set a value for the update.");
      return;
    }

    try {
      const updateData = {
        config_name: configToUpdate,
        config_value: bulkUpdateValue,
        sids: selectedRows,
        user: user
      };
      const response = await ApiManager.SERIALS.CONFIGS_UPDATE(updateData);
      await handleApiError(response);
      fetchSerialConfigurations();
      if (selectedSerial) {
        fetchLogs(selectedSerial); // Refetch logs after bulk update
      }
    } catch (error) {
      console.error("Failed to execute bulk update:", error);
      alert("An error occurred during the bulk update.");
    }
  };

  const handlePartNumberChange = (event) => {
    const partNumber = event.target.value;
    setCurrentPartNumber(partNumber);
  };

  const handleCellEditCommit = async (params) => {
    if (params.field === 'sid' || params.field === 'name') {
      return;
    }
    console.log(serials)
    const oldValue = serials.find((serial) => serial.id === params.id)[params.field];
    if (params.value === oldValue) {
      console.log('Value did not change. No update necessary.');
      return;
    }

    try {
      const updateData = {
        config_name: params.field,
        config_value: params.value,
        sids: [parseInt(params.id)],
        user: user
      };
      console.log("UPDATE", updateData);
      const response = await ApiManager.SERIALS.CONFIGS_UPDATE(updateData);
      const responseData = await handleApiError(response);

      alert('Configuration updated successfully!');
      fetchSerialConfigurations();
      if (selectedSerial) {
        fetchLogs(selectedSerial); // Refetch logs after edit commit
      }
    } catch (error) {
      console.error('Error updating configuration:', error);
      alert('An error occurred while updating. Please try again.');
    }
  }

  const handleChangeValue = (index, newValue) => {
    setEditedDeviationConfig(prev => prev.map((value, idx) => idx === index ? newValue : value));
  };

  const handleAddValue = () => {
    setEditedDeviationConfig(prev => [...prev, '']);
  };

  const handleDeleteValue = (index) => {
    setEditedDeviationConfig(prev => prev.filter((_, idx) => idx !== index));
  };

  const handleSave = async () => {
    if (!currentPartNumber) {
      alert('Please select a part number.');
      return;
    }

    if (editedDeviationConfig.some(value => !value.trim())) {
      alert('Configuration values cannot be empty.');
      return;
    }

    const uniqueValues = new Set(editedDeviationConfig);
    if (uniqueValues.size !== editedDeviationConfig.length) {
      alert('Configuration values must be unique.');
      return;
    }

    try {
      const configData = {
        configs: editedDeviationConfig,
        group_id: null
      };
      const response = await ApiManager.PARTS.ADDCONFIG(configData, currentPartNumber);
      await handleApiError(response);
      fetchSerialConfigurations();
      getConfigColumns();
      if (response.error) {
        console.error('Failed to save deviation configuration:', response.error);
        alert('Failed to save configuration. Please try again.');
        return;
      }

      alert('Deviation configuration saved successfully!');
    } catch (error) {
      console.error('Error saving deviation configuration:', error);
      alert('An error occurred while saving. Please try again.');
    }
  };

  React.useCallback(
    async (params) => {
      if (params.field === 'sid' || params.field === 'name') {
        return;
      }

      const oldValue = serials.find((serial) => serial.id === params.id)[params.field];

      if (params.value === oldValue) {
        console.log('Value did not change. No update necessary.');
        return;
      }

      try {
        const updateData = {
          config_name: params.field,
          config_value: params.value,
          sids: [parseInt(params.id)],
          user: user
        };
        console.log("UPDATE", updateData);
        const response = await ApiManager.SERIALS.CONFIGS_UPDATE(updateData);
        await handleApiError(response);

        alert('Configuration updated successfully!');
        fetchSerialConfigurations();
        if (selectedSerial) {
          fetchLogs(selectedSerial); // Refetch logs after edit commit
        }
      } catch (error) {
        console.error('Error updating configuration:', error);
        alert('An error occurred while updating. Please try again.');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleApiError, serials, selectedSerial]);


  return (
    <div style={{ margin: 20 }}>
      <FormControl fullWidth style={{ marginBottom: 20 }}>
        <Autocomplete
          options={parts}
          getOptionLabel={(option) => option.name}
          style={{ width: 300, marginBottom: 20 }}
          renderInput={(params) => <TextField {...params} label="Select Part Number" variant="outlined" />}
          value={parts.find(part => part.id === currentPartNumber)}
          onChange={(event, newValue) => {
            setCurrentPartNumber(newValue ? newValue.id : '');
          }}
          key={currentPartNumber}
        />
      </FormControl>

      {currentPartNumber && (
        <div>
          <Box sx={{ marginTop: 2 }}>
            {editedDeviationConfig.length > 0 ? (
              editedDeviationConfig.map((value, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                  <TextField
                    label={`Value ${index + 1}`}
                    variant="outlined"
                    value={value}
                    onChange={(e) => handleChangeValue(index, e.target.value)}
                  />
                  <IconButton onClick={() => handleDeleteValue(index)} color="error">
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))
            ) : (
              <Typography variant="subtitle1" style={{ margin: 10 }}>
                No configuration values. Add new values using the "+" button.
              </Typography>
            )}
            <IconButton onClick={handleAddValue} color="primary" size="large">
              <AddIcon />
            </IconButton>
            <Button onClick={handleSave} variant="contained" color="secondary" style={{ marginTop: 20 }}>
              Save Configurations
            </Button>
          </Box>
          <Box sx={{ marginTop: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
            <Autocomplete
              options={editableConfigs}
              getOptionLabel={(option) => option}
              renderInput={(params) => <TextField {...params} label="Configuration to Update" variant="outlined" />}
              value={configToUpdate}
              onChange={(event, newValue) => {
                setConfigToUpdate(newValue);
              }}
              style={{ width: 300 }}
            />
            <TextField
              label="New Value"
              variant="outlined"
              value={bulkUpdateValue}
              onChange={(e) => setBulkUpdateValue(e.target.value)}
              style={{ width: 150 }}
            />
            <Button onClick={handleBulkUpdate} variant="contained" color="primary">
              Update Selected
            </Button>
          </Box>

          {currentPartNumber && (
            <Table
              columns={columns}
              fetchData={() => {
                return { result: serials, size: serials.length }
              }}
              key={columns}
              sx={{ height: '500px' }}
              id={"configurationsTable"}
              onCellClick={() => { }}
              doFetchIndex={serials}
              paginationMode={"client"}
              onRowClick={onRowClick}
              onSelectionModelChange={onSelectionModelChange}
              handleCellEditCommit={handleCellEditCommit}
              checkboxSelection={true}
            />
          )}
          {logs.length > 0 && (
            <Box ref={logsRef} sx={{ height: 300, width: '100%', marginTop: 2 }}>
              <Typography variant="h6" style={{ margin: 10 }}>Logs</Typography>
              <Table
                columns={logColumns}
                fetchData={() => {
                  return { result: logs.map(log => ({ ...log, id: log.log_id })), size: logs.length }
                }}
                sx={{ height: '500px' }}
                id={"configurationsLog"}
                onCellClick={() => { }}
                doFetchIndex={logs}
                paginationMode={"client"}
                location={"tableData_configurations_log"}
              />
            </Box>
          )}
        </div>
      )}
    </div>
  );
}

export default Configurations;
