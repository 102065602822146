const getHeaders = () => {
	return {
		"Content-Type": "application/json",
		Accept: "application/json",
	};
};

class ApiMethods {
	static apiRequest(method, url, body = {}) {
		return new Promise((resolve, reject) => {
			const requestOptions = {
				method: method,
				credentials: "include",
			};

			if (!(body instanceof FormData)) {
				requestOptions.headers = getHeaders();
			}

			if (method === "POST" || method === "PUT") {
				if (body instanceof FormData) {
					requestOptions.body = body;
				} else {
					requestOptions.body = JSON.stringify(body);
				}
			}

            console.log(`Fetching [${method.toUpperCase()}]: ${url}`, Object.keys(body).length > 0 ? body : "");

			fetch(url, requestOptions)
				.then((response) => {
                    console.log(`Recieved [${method.toUpperCase()} - ${response.status}]: ${url}`, Object.keys(body).length > 0 ? body : "");
					resolve(response);
				})
				.catch((error) => {
					resolve({ message: "Network or unknown error", error: error });
				});
		});
	}

	static get(url) {
		return this.apiRequest("GET", url);
	}

	static post(url, data) {
		return this.apiRequest("POST", url, data);
	}

	static put(url, data) {
		return this.apiRequest("PUT", url, data);
	}
}

export default ApiMethods;
