import React, { useEffect, useState } from 'react';
import { DateForm } from '../../components';
import Button from '@mui/material/Button';

import './testList.css';
import { ChatBubbleOutline } from "@material-ui/icons";

import { Link } from 'react-router-dom';
import Table from '../../components/table/Table'
import HoldButton from '../../components/holdbutton/HoldButton'
import ApiManager from '../../api_utils/ApiManager';
import ApiError from '../../api_utils/ApiError';


//THIS IS WHERE /part route
export default function TestList() {
  const location = "tableSettings_parts"
  const handleApiError = ApiError();
  const [timeQuerySettings, setTimeQuerySettings] = useState({});
  const [fetchIndex, setFetchIndex] = useState(0)
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)

  // const [partLink, setPartLink] = useState('')
  const columns = [
    {
      field: 'partNum', headerName: 'Part Number', minWidth: 91, flex: 0.14, type: 'string',
      renderCell: (params) => {
        const url = `${process.env.REACT_APP_LOCAL_ROUTE}/yield?part=${params.value}`;
        return (
          <HoldButton params={params} showLink={true} url={url} color={"#2d5ca9"} fontSize={params.row.fontSize} />
        )
      }, canShowLink: true
    },
    {
      field: 'description', headerName: 'Part Description', minWidth: 210, flex: 0.3
    },
    {
      field: 'serial', headerName: 'Serial Number', minWidth: 122.5, flex: 0.175,
      renderCell: (params) => {
        const url = `${process.env.REACT_APP_LOCAL_ROUTE}/log/${params.value}?sid=${params.id}`;
        return (
          <HoldButton params={params} url={url} color={"#2d5ca9"} fontSize={params.row.fontSize} />
        )
      }, canShowLink: true
    },
    {
      field: 'station', headerName: 'Station', minWidth: 59.5, flex: 0.085
    },
    {
      field: 'lastTest', headerName: 'Last Test', minWidth: 77, flex: 0.11
    },
    // { field: 'processStartDate', headerName: 'Process Start Date', minWidth: 250, flex: 0.3 , editable : true},
    // { field: 'testDate', headerName: 'Test Date', minWidth: 250 , flex: 0.3},
    {
      field: 'lastSeen', headerName: 'Last Seen', minWidth: 38.5, flex: 0.065,
    },
    {
      field: 'timeWIP', headerName: 'Time in Wip', minWidth: 38.5, flex: 0.065,
    },
    // {
    //   field: 'request',
    //   headerName: 'Request',
    //   sortable: false,
    //   // type : 'actions' ,
    //   disableClickEventBubbling: true,
    //   renderCell: (params) => {
    //     const api = params.api;
    //     const thisRow = {};
    //     api
    //       .getAllColumns().filter((c) => c.field !== '__check__' && !!c)
    //       .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)),)
    //     // console.log(thisRow['serial'])
    //     thisRow['id'] = params.id
    //     return (<div>
    //       <DataGridButton data={thisRow} />
    //     </div>
    //     )
    //   },
    // }
  ];


  const fetchData = async (querySettings) => {
    try {
      setLoading(true)
      const data = await ApiManager.PARTS.POSTLIST(querySettings);
      const dataName = await handleApiError(data);

      setRows(dataName)
    } catch (error) {
      console.error('TestList.jsx: Error Fetching Data', querySettings, error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (fetchIndex !== 0)
      fetchData(timeQuerySettings)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchIndex])

  return (
    <div>
      <br />
      <DateForm updateQuerySettings={(data) => {
        setTimeQuerySettings({ ...timeQuerySettings, ...data })

        setFetchIndex(fetchIndex + 1)
      }} />
      <Table
        columns={columns}
        fetchData={() => {
          return { result: rows, size: rows.length }
        }}
        location={location}
        doFetchIndex={rows}
        externalQuerySettings={timeQuerySettings}
        sx={{ height: 600 }}
        id={"Table1"}
        paginationMode={"client"}
        loading={loading}
      />
    </div>
  );
}