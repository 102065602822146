import * as React from 'react';
import Modal from '@mui/material/Modal';
import {RequestForm }from '../.'


export default function RequestFormModal({open,handleClose,data, pref, summaryFill, isECO, handleApiError}) {
    return (
      <div>
          <Modal

    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
      <div>
        
      <RequestForm data={data} handleClose={handleClose} pref={pref} summaryFill = {summaryFill} isECO = {isECO} type="modal" handleApiError={handleApiError} requireJira={(data.production_status && data.production_status === "Production" && !isECO)} />

      </div>
    </Modal>
      </div>

    )
}