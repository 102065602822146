import { useDispatch } from "react-redux";
import { loginFailure, logout } from "../store/auth/authStateSlice";
import { setError } from "../store/error/errorSlice";

const ApiError = () => {
	const dispatch = useDispatch();

	const handleApiErrorCode = async (error) => {
		console.log("Handling API Error Code:", error.status);

		switch (error.status) {
			case 200:
			case 307:
				return await error.json();
			case 400:
			case 404:
			case 422:
			case 500:
				dispatch(setError(error));
				console.log(`${error.status}: ${error.statusText}`);
				return error.statusText || "Error occurred";
			case 401:
				dispatch(logout());
				dispatch(loginFailure(error));
				return "Unauthorized";
			default:
				dispatch(setError(error));
				return "Unknown Error";
		}
	};

	return handleApiErrorCode;
};

export default ApiError;
