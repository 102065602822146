import React, { useEffect, useState } from 'react'

import ApiManager from '../../api_utils/ApiManager';
import ApiError from '../../api_utils/ApiError';
import ProgressBar from '../../components/progressbar/ProgressBar';
import { LinearProgress, Typography } from '@mui/material';

import Table from '../../components/table/Table'
import HoldButton from '../../components/holdbutton/HoldButton';

/**
 * The Mac Addresses Page. Shows utilization
 * @returns 
 */
const MacAddresses = () => {

    //The columns. Parts links to yield, serials link to logs
    const columns = [
        {
            field: 'mac_address',
            headerName: "MAC Address",
            flex: 1 / 4,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'part_name',
            headerName: 'Part Number',
            flex: 1 / 4,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <HoldButton url={`${process.env.REACT_APP_LOCAL_ROUTE}/yield?part=${params.value}`} params={params} color={"#2d5ca9"} fontSize={params.row.fontSize}>
                    <Typography variant="subtitle1" sx={{ textAlign: 'center', padding: '8px' }}>
                        {params.value}
                    </Typography>
                </HoldButton>
            ),
            valueGetter: (params) => params.value
        },
        {
            field: 'serial_name',
            headerName: "Assigned Serial",
            flex: 1 / 4,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <HoldButton url={`${process.env.REACT_APP_LOCAL_ROUTE}/log/${params.value}?sid=${params.row.serial_id}`} params={params} color={"#2d5ca9"} fontSize={params.row.fontSize}>
                    <Typography variant="subtitle1" sx={{ textAlign: 'center', padding: '8px' }}>
                        {params.value}
                    </Typography>
                </HoldButton>
            ),
            valueGetter: (params) => params.value
        },
        {
            field: 'assignment_date',
            headerName: 'Assignment Date',
            flex: 1 / 4,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const date = new Date(params.value)
                    return date.toLocaleDateString() + " " + date.toLocaleTimeString()
            }
        }
    ];

    const handleError = ApiError()

    const [assignedMacs, setAssignedMacs] = useState([]);
    const [loadingAssignedMacs, setLoadingAssignedMacs] = useState(false)
    const [macUtilization, setMacUtilization] = useState([]);
    const [loadingMacUtilization, setLoadingMacUtilization] = useState(false)
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    /**
     * Fetches the assigned mac addresses. Also gives each their unique ID property using assignment_id
     */
    const fetchAssignedMacs = async () => {
        setLoadingAssignedMacs(true)
        ApiManager.MAC.ASSIGNED()
            .then(async (data) => await handleError(data))
            .then((data) => setAssignedMacs(data.map(item => {
                const { assignment_id, ...rest } = item;
                return { id: assignment_id, ...rest };
            })))
            .then(() => setLoadingAssignedMacs(false))
    }

    /**
     * Fetches the MAC utilization bunches
     */
    const fetchMacUtilization = async () => {
        setLoadingMacUtilization(true)
        ApiManager.MAC.UTILIZATION()
            .then(async (data) => await handleError(data))
            .then((data) => setMacUtilization(data))
            .then(() => setLoadingMacUtilization(false))
    }

    /**
     * On Load, fetch the utilization and assigned macs
     */
    useEffect(() => {
        fetchAssignedMacs()
        fetchMacUtilization()
        // eslint-disable-next-line
    }, [])

    /**
     * Used for full screening the chart. Adds an event listener for resizing the window to get the correct size of the chart
     */
    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            {loadingMacUtilization ? (
                <LinearProgress
                    sx={{
                        width: '100%', height: 10, borderRadius: 5, '& .MuiLinearProgress-bar': {
                            backgroundColor: '#2d5ca9',
                        },
                    }}
                />
            ) : (
                <ProgressBar
                    label={`${macUtilization[macUtilization.length - 1]?.total_addresses - macUtilization[macUtilization.length - 1]?.remaining_addresses} / ${macUtilization[macUtilization.length - 1]?.total_addresses}`}
                    percentage={macUtilization[macUtilization.length - 1]?.utilization_percentage}
                />
            )}
            <Table
                columns={columns}
                fetchData={() => { return { result: assignedMacs, size: assignedMacs.length } }}
                sx={{ height: windowHeight - 168, marginTop: '48px' }}
                isLoading={loadingAssignedMacs}
                doFetchIndex={assignedMacs}
                id={"assignedMACAddressTable"}
                paginationMode={"client"}
                location={"tableSettings_assignedMACAddress"}
            />
        </div>
    )
}

export default MacAddresses