import React, { useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js'
import MUIRichTextEditor from 'mui-rte'
import { FileUploadForm } from '../.'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ApiManager from '../../api_utils/ApiManager';
import { IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
// import ApiError from '../../api_utils/ApiError';
const style = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
};


export default function RequestForm({ data, type, handleClose, pref, summaryFill, isECO, handleApiError, requireJira }) {
  // const blocksFromHTMLtemp = convertFromHTML("<div> </div>");
  // const contentStateTemp = ContentState.createFromBlockArray(
  //   blocksFromHTMLtemp.entityMap,
  // );
  // const rawContentState = convertToRaw(contentStateTemp);
  // const jsonStateTemp = JSON.stringify(rawContentState);
  const authData = useSelector((state) => state.auth);
  const { user } = authData;
  const [error, setError] = React.useState('')
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [richText, setRichText] = React.useState('')
  const [jiraTicket, setJiraTicket] = React.useState('');
  const [draft, setDraft] = React.useState(JSON.stringify(convertToRaw(ContentState.createFromText(""))))
  // const handleApiError = ApiError()
  const handleFilesSelected = (selectedFiles) => {
    setSelectedFiles(selectedFiles)
  };

  const htmlToDraft = (html) => {
    const blocksFromHTML = convertFromHTML(html);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    const rawContentState = convertToRaw(contentState);
    const jsonState = JSON.stringify(rawContentState);
    setDraft(jsonState)
    // return jsonState;
  };

  const verifyJiraTicket = () => {
    return requireJira ? jiraTicket.includes("https://eridan.atlassian.net") : true;
  }

  useEffect(() => {

    htmlToDraft(pref)
  }, [])

  const requestTheme = createTheme({
    // Set up your custom MUI theme here
    MUIRichTextEditor: {

    }

  });
  Object.assign(requestTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          width: "100%",
          height: '100%',
          maxHeight: "450px",
          border: '1px solid black'
        },
        editor: {
          height: '100%',
          overflowY: 'auto',
          maxHeight: '350px',
          padding: '20px'
        },
        placeHolder: {
          padding: '20px',
          width: 'inherit',
          position: 'static',
          height: '100%',
          maxHeight: '100%',
          overflowY: 'auto'

        },
        anchorLink: {
          color: '#FFEB3B',
          textDecoration: 'underline',

        },
      }
    }
  })

  const handleSubmit = async (e) => {
    e.preventDefault();

    let summary = e.target.summary.value;
    if (summary.length === 0) {
      setError('Input cannot be empty')
    }
    else if (!verifyJiraTicket()) {
      setError("Jira Issue Ticket Must Be Included")
    }
    else {
      setError('')
      let formData = new FormData();
      formData.append('requester', user);
      formData.append('details', richText);
      formData.append('summary', summary);
      formData.append('jira_ticket', jiraTicket);
      formData.append('serial_id', data.id);
      formData.append('serial_number', data.serial);
      formData.append("is_eco", isECO)
      if (selectedFiles.length === 0) {
        formData.append('upload_files', new File([], 'placeholder'));
      }
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('upload_files', selectedFiles[i]);
      }

      setLoading(true)

      try {
        const d = await ApiManager.QUEUE.ADDREQUEST(formData)
        const response = await handleApiError(d)

        setLoading(false)

        handleClose(e)
      }
      catch (error) {
        setLoading(false)
        console.error("Error:", error);
      }
      // fetch(`https://${process.env.REACT_APP_SERVER_ROUTE}/addrequest`, {
      // method: 'POST',
      // body: formData
      // }
      // )
      // .then(response => response.json())
      // .then(data => {

      // setLoading(false)

      // handleClose(e)
      // })
      // .catch(error => {
      //     setLoading(false)
      // console.error("Error:", error);
      // });

    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <Box sx={style[type]}>
        <div style={{ position: 'relative' }}>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 0, right: 0, color: 'red' }}><ClearIcon /></IconButton>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h3" component="h2">
              Request
            </Typography>
          </div>


          <Stack spacing={2}>
            <Typography variant="h5" component="h2">
              Part Number: {data.partNum}
            </Typography>
            <Typography variant="h5" component="h2">
              Serial Number: {data.serial}
            </Typography>
            <Typography variant='h5'>Requestor: {user}</Typography>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red' }}>
              <Typography variant="h5" component="h5">
                {error}
                {isLoading &&
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                  </Box>
                }
              </Typography>
            </div>


            <TextField
              id="outlined-multiline-static"
              label="Summary"
              multiline
              rows={1}
              name="summary"

              defaultValue={summaryFill}
            />
            <TextField
              id="jira_ticket"
              label="Jira Ticket"
              name='Jira Ticket'
              value={jiraTicket}
              onChange={(e) => setJiraTicket(e.target.value)}
              error={error == "Jira Issue Ticket Must Be Included"}
            />
            <div style={{}}>
              <ThemeProvider theme={requestTheme}>
                <MUIRichTextEditor label="Start typing comments"
                  defaultValue={draft}
                  onChange={(e) => {
                    const rteContent = convertToRaw(e.getCurrentContent()) // for rte content with text formating
                    rteContent && setRichText(JSON.stringify(rteContent))
                  }}
                ></MUIRichTextEditor>
              </ThemeProvider>
            </div>
            <FileUploadForm
              id="outlined-multiline-static"
              label="uploads"
              name="uploads"
              onFilesSelected={handleFilesSelected}
            />
            <Button variant="contained" type='submit' endIcon={<SendIcon />}>
              Submit
            </Button>
          </Stack>

        </div>
      </Box>
    </form>
  )
}