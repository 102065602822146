import ApiMethods from "../ApiMethods";
import ENDPOINTS from "../EndPoints";

class EquipmentManager {
	static ENDPOINTS = {
		GETTYPELIST: () => `/equipment/typeslist`,
		PUTFORMVALUE: () => `/equipment`,
		GETEQUIPMENTDETAIL: () => `/equipment`,
		GETEQUIPMENTLOGDETAIL: () => `/equipment/log`,
	};

	static PUT_DETAIL = (param, body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.PUTFORMVALUE() + "/" + param;
		return ApiMethods.put(url, body);
	};

	static DETAIL = (param) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.GETEQUIPMENTDETAIL() + param;
		return ApiMethods.get(url);
	};

	static DETAIL_LOG = (param) => {
		const url =
			ENDPOINTS.BASE_URL + this.ENDPOINTS.GETEQUIPMENTLOGDETAIL() + "/" + param;
		return ApiMethods.get(url);
	};

	static DETAIL_TYPELIST = () => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.GETTYPELIST();
		return ApiMethods.get(url);
	};
}

export default EquipmentManager;
