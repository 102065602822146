import ApiMethods from "../ApiMethods";
import ENDPOINTS from "../EndPoints";

class Parts {
	static ENDPOINTS = {
		ADDPARTNUMBER: () => `/addpartnumber`,
		PARTSEARCHLIST: () => `/partsearchlist`,
	};

	static ADD = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.ADDPARTNUMBER();
		return ApiMethods.post(url, body);
	};

	static SEARCH = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.PARTSEARCHLIST();
		return ApiMethods.post(url, body);
	};
}

class Serials {
	static ENDPOINTS = {
		ADDSERIALNUMBER: () => `/addserialnumber`,
		SERIALSEARCHLIST: () => `/serialsearchlist`,
		GENERATE_SERIAL: () => `/serials/generate`
	};

	static ADD = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.ADDSERIALNUMBER();
		return ApiMethods.post(url, body);
	};

	static SEARCH = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.SERIALSEARCHLIST();
		return ApiMethods.post(url, body);
	};

	static GENERATE = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.GENERATE_SERIAL();
		return ApiMethods.post(url, body);
	}
}

class Components {
	static ENDPOINTS = {
		ADDCOMPONENTS: () => `/addcomponents`,
		GETCOMPONENTS: () => `/getComponents`,
		COMPONENTSEARCHLIST: () => `/componentsearchlist`,
	};

	static ADD = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.ADDCOMPONENTS();
		return ApiMethods.post(url, body);
	};

	static GET = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.GETCOMPONENTS();
		return ApiMethods.post(url, body);
	};

	static SEARCH = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.COMPONENTSEARCHLIST();
		return ApiMethods.post(url, body);
	};
}

class IntakeManager {
	static PARTS = Parts;
	static SERIALS = Serials;
	static COMPONENTS = Components;
}

export default IntakeManager;
