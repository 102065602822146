import ApiMethods from "../ApiMethods";
import ENDPOINTS from "../EndPoints";

class SerialManager {

	static ENDPOINTS = {
		CONFIGS_LOGS: (sid) => `/serials/${sid}/config/logs`,
		CONFIGS: () => `/serials/config`,
	};

	static CONFIG_LOGS = (sid) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.CONFIGS_LOGS(sid);
		return ApiMethods.get(url);
	};

	static CONFIGS_UPDATE(updateData) {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.CONFIGS() + `/update`;
		return ApiMethods.post(url, updateData);
	}
}

export default SerialManager
