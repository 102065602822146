import React, { useEffect, useState } from 'react'
import GroupedDropDown from '../../components/dropdown/GroupedDropDown'
import DropDownSelector from '../../components/dropdown/DropDownSelector'

import { Box, Button, FormControlLabel, FormGroup, LinearProgress, Modal, Switch, Typography, Grid } from '@mui/material'

import PartNumberStatusTable from '../../components/partnumberstatus/PartNumberStatusTable';

import { Check, Close, Settings } from '@material-ui/icons'
import ReorderIcon from '@mui/icons-material/Reorder';

import ApiManager from '../../api_utils/ApiManager'
import ApiError from '../../api_utils/ApiError'
import AggregatedLog from '../../components/partnumberstatus/AggregatedLog';
import BuildOrderModal from './BuildOrderModal';

/**
 * The landing page, containing a table of builds' important parts' status over time
 * @returns the landing page
 */
const Landing = () => {

    const localStorageKey = "landingPage"

    const handleApiError = ApiError()

    const timeOptions = [
        { groupName: "Weeks", options: ["1 Week", "2 Weeks", "3 Weeks"] },
        { groupName: "Months", options: ["1 Month", "2 Months", "3 Months", "6 Months"] },
        { groupName: "Years", options: ["1 Year",] },
    ]

    /**
     * The color options
     */
    const timeChartDisplayOptions = {
        Completed: 'rgba(0, 128, 0, 1)',
        Incomplete: 'rgba(128, 128, 128, 1)',
        Fail: 'rgba(255, 0, 0, 1)',
        Stale: 'rgba(235, 152, 42, 1)'
    }

    /**
     * Gets the default information from local storage if it's there. otherwise, defaults it
     * @returns the default information
     */
    const getDefaultsFromLocalStorage = () => {
        const obj = JSON.parse(localStorage.getItem(localStorageKey)) || {}
        console.log("LOCALSTORAGE", obj)

        const selectedBuild = (obj.selectedBuild || undefined)
        const useFractions = (typeof obj.useFractions === 'boolean') ? obj.useFractions : true;
        const selectedTimeFrame = (obj.selectedTimeFrame && timeOptions.some((option) => option.options.includes(obj.selectedTimeFrame))) ? obj.selectedTimeFrame : timeOptions[0].options[0]
        const buildOrders = obj.buildOrders

        return { selectedBuild, useFractions, selectedTimeFrame, buildOrders }
    }

    const localStorageData = getDefaultsFromLocalStorage()

    const [selectedTimeFrame, setSelectedTimeFrame] = useState(localStorageData.selectedTimeFrame)
    const [selectedTimeChartDisplayOptions, setSelectedTimeChartDisplayOptions] = useState(Object.keys(timeChartDisplayOptions))
    const [isDisplaySettingsOpen, setDisplaySettingsOpen] = useState(false)
    const [isOrderingOpen, setIsOrderingOpen] = useState(false)
    const [buildOptions, setBuildOptions] = useState({});
    const [selectedBuild, setSelectedBuild] = useState(localStorageData.selectedBuild)
    const [buildOrders, setBuildOrders] = useState({})
    const [useFractions, setUseFractions] = useState(localStorageData.useFractions)
    const [isLoading, setIsLoading] = useState(false)
    const [fetchState, setFetchState] = useState(true);
    const [logPartNumbers, setLogPartNumbers] = useState([]);

    /**
     * Gets the highest rank from the builds
     * @param {Object} data the build data
     * @returns the highest rank build
     */
    const getCurrentBuild = (data) => {
        let highestRank = -1;
        let highestRankKey = null;

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const rank = data[key].rank;
                if (rank > highestRank) {
                    highestRank = rank;
                    highestRankKey = key;
                }
            }
        }
        return highestRankKey ? highestRankKey : null;
    }

    /**
     * Fetches the build options
     */
    useEffect(() => {
        setIsLoading(true)
        ApiManager.BUILDS.GET()
            .then((response) => {
                handleApiError(response)
                    .then((data) => {

                        const storedOrders = localStorageData.buildOrders;
                        if (storedOrders) {
                            Object.keys(data).forEach((key) => {
                                if (storedOrders[key] && Array.isArray(storedOrders[key].parts)) {
                                    data[key].parts = data[key].parts.sort((a, b) => {
                                        try {
                                            return storedOrders[key].parts.find((part) => part.partNumber === a.partNumber)?.rank - storedOrders[key].parts.find((part) => part.partNumber === b.partNumber)?.rank
                                        } catch {
                                            return 1;
                                        }
                                    })
                                }
                                data[key].parts = data[key].parts.map((part, index) => ({ ...part, rank: index }))
                            })
                        } else {
                            console.log("here123")
                            Object.keys(data).forEach((key) => {
                                data[key].parts = data[key].parts.map((part, index) => ({ ...part, rank: index }))
                            })
                        }
                        setBuildOptions(data)
                        setBuildOrders(Object.fromEntries(
                            Object.entries(data).map(([key, value]) => [
                                key, {
                                    rank: value.rank,
                                    parts: value.parts.map(part => ({
                                        partNumber: part.partNumber,
                                        partAlias: part.partAlias,
                                        rank: part.rank
                                    }))
                                }
                            ])
                        ))
                        console.log(data)
                        setIsLoading(false)
                        if (!Object.keys(data).includes(selectedBuild)) {
                            setSelectedBuild(getCurrentBuild(data))
                        }
                    })
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Updates local storage on build, timeframe or use fractions changing
     */
    useEffect(() => {
        localStorage.setItem(localStorageKey, JSON.stringify({
            selectedBuild,
            selectedTimeFrame,
            useFractions,
            buildOrders
        }))
    }, [selectedBuild, selectedTimeFrame, useFractions, buildOrders])

    useEffect(() => {
        setLogPartNumbers(buildOptions[selectedBuild]?.parts.map((part) => ({ enabled: true, part })));
    }, [selectedBuild, buildOptions])

    return (
        <div style={{ overflowX: 'clip', width: '100%' }}>
            {/* Header Component Containing Time Options, Build Options and Display Options */}
            <div style={{ width: '100%', position: 'relative', display: 'flex' }}>
                <div style={{ position: 'absolute', top: 0, left: 'calc(50% - 60px)', width: '100%' }}>
                    <DropDownSelector
                        options={(() => {
                            let k = Object.keys(buildOptions).sort((a, b) => buildOptions[a].rank - buildOptions[b].rank);
                            const miscIndex = k.indexOf('MISC');
                            if (miscIndex !== -1) {
                                k.splice(miscIndex, 1);
                                k.push('MISC');
                            }
                            return k;
                        })() || []}
                        title={"Selected Build"}
                        selection={selectedBuild}
                        setSelection={setSelectedBuild}
                        key={Object.keys(buildOptions).length}
                        starredItems={[getCurrentBuild(buildOptions)]}
                    />
                </div>
                <div style={{ display: 'flex', placeContent: 'space-between', width: '100%' }}>
                    <GroupedDropDown
                        options={timeOptions}
                        title={"Time Frame"}
                        selection={selectedTimeFrame}
                        setSelection={setSelectedTimeFrame}
                    />
                    <div style={{ height: '100%' }}>
                        <Button
                            startIcon={<ReorderIcon />}
                            onClick={() => setIsOrderingOpen(true)}
                            sx={{ textTransform: 'none', paddingLeft: '16px', paddingRight: '16px', marginRight: '8px', height: '100%' }}
                        >
                            Part Order
                        </Button>
                        <Button
                            startIcon={<Settings />}
                            onClick={() => setDisplaySettingsOpen(true)}
                            sx={{ textTransform: 'none', paddingLeft: '16px', paddingRight: '16px', marginRight: '8px', height: '100%' }}
                        >
                            Display Settings
                        </Button>
                    </div>
                    <BuildOrderModal isOpen={isOrderingOpen} onClose={() => setIsOrderingOpen(false)} buildOrders={buildOrders} setBuildOrders={setBuildOrders} />
                    <Modal open={isDisplaySettingsOpen} onClose={() => setDisplaySettingsOpen(false)}>
                        <Box sx={{ width: 400, bgcolor: 'background.paper', p: 4, mx: 'auto', mt: '20%', borderRadius: 2 }}>
                            <Typography variant="h6" component="h2">
                                Display Settings
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="p" component="p" sx={{ marginTop: 2, marginLeft: 2 }}>
                                        Table Settings
                                    </Typography>
                                    <FormGroup sx={{ marginLeft: 4 }}>
                                        {Object.keys(timeChartDisplayOptions).map((key) => (
                                            <FormControlLabel
                                                key={key}
                                                control={
                                                    <Switch
                                                        checked={selectedTimeChartDisplayOptions.includes(key)}
                                                        onChange={() => setSelectedTimeChartDisplayOptions(() => {
                                                            if (selectedTimeChartDisplayOptions.includes(key))
                                                                return selectedTimeChartDisplayOptions.filter((o) => o !== key);
                                                            else
                                                                return [...selectedTimeChartDisplayOptions, key]
                                                        })}
                                                        name={key}
                                                        sx={{
                                                            '& .MuiSwitch-switchBase.Mui-checked': {
                                                                color: timeChartDisplayOptions[key],
                                                                '&:hover': {
                                                                    backgroundColor: `${timeChartDisplayOptions[key]}22`,
                                                                },
                                                            },
                                                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                                backgroundColor: timeChartDisplayOptions[key],
                                                            },
                                                        }}
                                                    />
                                                }
                                                label={key}
                                                sx={{ color: timeChartDisplayOptions[key] }}
                                            />
                                        ))}
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="p" component="p" sx={{ marginTop: 2, marginLeft: 2 }}>
                                        Number Settings
                                    </Typography>
                                    <FormGroup sx={{ marginLeft: 4 }}>
                                        <FormControlLabel
                                            key={"useFractions"}
                                            control={
                                                <Switch
                                                    checked={!useFractions}
                                                    onChange={() => setUseFractions(!useFractions)}
                                                    name={"useFractions"}
                                                />
                                            }
                                            label={"Percentages"}
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Box>
                    </Modal>
                </div>
            </div>

            {/* A linear progress bar to show if the page is loading information */}
            {isLoading ?
                <Box sx={{ width: '100%', height: '4px' }}>
                    <LinearProgress />
                </Box>
                :
                <div style={{ height: '4px' }} />
            }
            <PartNumberStatusTable
                useFractions={useFractions}
                selectedBuild={selectedBuild}
                buildOptions={buildOptions}
                setIsLoading={setIsLoading}
                displayOptions={selectedTimeChartDisplayOptions}
                selectedTimeFrame={selectedTimeFrame}
                fetchState={fetchState}
                setFetchState={(setFetchState)}
                buildOrders={buildOrders}
            />


            <fieldset style={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between', paddingBottom: '14px', borderRadius: '4px' }}>
                <legend><Typography variant='body1' component="h2" sx={{ padding: '0 4px' }} >Log Filter</Typography></legend>
                <div style={{ display: 'flex' }}>
                    {logPartNumbers?.map((part, index) => (
                        <FormControlLabel key={index} control={
                            <Switch key={index} checked={part.enabled} onChange={() => {
                                const newPartNumbers = [...logPartNumbers];
                                newPartNumbers[index].enabled = !newPartNumbers[index].enabled;
                                setLogPartNumbers(newPartNumbers);
                            }} />
                        } label={part.part.partAlias} />
                    ))}
                </div>
                <div>
                    <Button onClick={() => setLogPartNumbers(logPartNumbers.map((part) => ({ ...part, enabled: true })))} startIcon={<Check />}>Select All</Button>
                    <Button onClick={() => setLogPartNumbers(logPartNumbers.map((part) => ({ ...part, enabled: false })))} startIcon={<Close />}>Select None</Button>
                </div>
            </fieldset>
            <AggregatedLog
                partNumbers={logPartNumbers?.filter((part) => part.enabled).map((part) => part.part)}
                includePartNumber
                tableID="tableSettings_landing_log"
            />
        </div>
    )
}

export default Landing