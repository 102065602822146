import AuthManager from './managers/AuthManager'
import QueueManager from "./managers/QueueManager";
import StationManager from "./managers/StationManager";
import YieldManager from "./managers/YieldManager";
import MacManager from "./managers/MacManager";
import PartManager from "./managers/PartManager";
import BuildManager from "./managers/BuildManager";
import EquipmentManager from './managers/EquipmentManager'
import LogManager from "./managers/LogManager";
import IntakeManager from "./managers/IntakeManager";
import ECOManager from "./managers/EcoManager";
import SerialManager from "./managers/SerialManager";
import StatusManager from './managers/StatusManager';

/**
 * A hub for all of the API Managers to go through.
 */
class ApiManager {

	static AUTH = AuthManager;
	static BUILDS = BuildManager;
	static ECO = ECOManager;
	static EQUIPMENT = EquipmentManager;
	static INTAKE = IntakeManager;
	static LOG = LogManager;
	static MAC = MacManager;
	static PARTS = PartManager;
	static QUEUE = QueueManager;
	static SERIALS = SerialManager;
	static STATION = StationManager;
	static STATUS = StatusManager;
	static YIELD = YieldManager;

}

export default ApiManager;
