import ApiMethods from "../ApiMethods";
import ENDPOINTS from "../EndPoints";

class StationManager {
	static ENDPOINTS = {
		GETLIST: () => `/getstationtest`,
		GETSTATIONTESTSDETAILS: () => `/stations/stationtest/gettests`,
		GETSTATIONTESTS: () => `/stations/gettests`,
		GETSTATIONLIST: () => `/stations/stationslist`,
	};

	static STATIONTEST = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.GETLIST();
		return ApiMethods.post(url, body);
	};

	static STATIONTESTDETAIL = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.GETSTATIONTESTSDETAILS();
		return ApiMethods.post(url, body);
	};

	static STATIONTESTS = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.GETSTATIONTESTS();
		return ApiMethods.post(url, body);
	};

	static STATIONLIST = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.GETSTATIONLIST();
		return ApiMethods.post(url, body);
	};

	static TESTLOG = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.STATION_GETLIST();
		return ApiMethods.post(url, body);
	};
}

export default StationManager;
