import * as React from 'react';
// import { Transition } from 'react-transition-group';
import { styled } from '@mui/system';
import { IoClose } from 'react-icons/io5';
import Snackbar from '@mui/material/Snackbar';

/**
 * a custom snackbar
 * 
 * @author Ben Kamin
 * 
 * @param {Boolean} open whether the snackbar is open
 * @param {Function} onClose function to close the snackbar
 * @param {Number} autoHideDuration the duration the snackbar should be open
 * @param {children} children the children of the snackbar
 * @param {Object} sx the style of the snackbar 
 * @returns the custom snackbar
 */
const CustomSnackbar = ({ open, onClose, autoHideDuration = 5000, children, sx = {} }) => {
    const nodeRef = React.useRef(null);

    const [status, setStatus] = React.useState('unmounted');

    React.useEffect(() => {
        if (open) {
            setStatus('entering');
        } else {
            setStatus('exiting');
        }
    }, [open]);

    return (
        <StyledSnackbar
            autoHideDuration={autoHideDuration}
            open={open}
            onClose={onClose}
            exited={!open}
        >
            <SnackbarContent
                style={{
                    transform: positioningStyles[status],
                    transition: 'transform 300ms ease',
                    width: '100%',
                    ...sx
                }}
                ref={nodeRef}
            >
                <div style={{
                    display: 'flex', placeItems: 'center', width: '100%'
                }}>
                    <IoClose onClick={onClose} className="snackbar-close-icon" />
                    {children}
                </div>
            </SnackbarContent>
        </StyledSnackbar>
    );
}

export default CustomSnackbar

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const StyledSnackbar = styled(Snackbar)`
    position: fixed;
    z-index: 5500;
    display: flex;
    left: auto;
    bottom: 16px;
    right: 16px; // Modified to appear at the bottom right
    max-width: 560px;
    min-width: 300px;
`;

const SnackbarContent = styled('div')(
    ({ theme }) => `
  display: flex;
  gap: 8px;
  overflow: hidden;
  background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border-radius: 8px;
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: ${theme.palette.mode === 'dark'
            ? `0 2px 16px rgba(0,0,0, 0.5)`
            : `0 2px 16px ${grey[200]}`
        };
  padding: 0.75rem;
  color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  text-align: start;
  position: relative;

  & .snackbar-message {
    flex: 1 1 0%;
    max-width: 100%;
  }

  & .snackbar-title {
    margin: 0;
    line-height: 1.5rem;
    margin-right: 0.5rem;
  }

  & .snackbar-description {
    margin: 0;
    line-height: 1.5rem;
    font-weight: 400;
    color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
  }

  & .snackbar-close-icon {
    cursor: pointer;
    flex-shrink: 0;
    padding: 2px;
    border-radius: 4px;

    &:hover {
      background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    }
  }
  `,
);

const positioningStyles = {
    entering: 'translateX(0)',
    entered: 'translateX(0)',
    exiting: 'translateX(-500px)',
    exited: 'translateX(-500px)',
    unmounted: 'translateX(-500px)',
};