import draftToHtml from 'draftjs-to-html';
import HtmlToReactParser from 'html-to-react';

const parser = new HtmlToReactParser.Parser();

/**
 * converts DraftJS String to ReactDOM elements
 * @param {String} comment the draft JS comment as a string
 * @returns the React DOM elements from the DraftJS comment
 */
export const convertCommentToReact = (comment) => {
  let reactElements = '';

  try {
    // Parse and preprocess the JSON
    let parsedComment

    try { parsedComment = JSON.parse(comment); } catch { parsedComment = comment }

    // Replace "text" with "unstyled"
    parsedComment.blocks = parsedComment.blocks.map(block => {
      if (block.type === 'text') {
        return { ...block, type: 'unstyled' };
      }
      return block;
    });

    // Convert the preprocessed JSON back to a string
    const preprocessedComment = JSON.stringify(parsedComment);

    // Convert the draft.js content to HTML
    const htmlContent = draftToHtml(JSON.parse(preprocessedComment));

    // Parse the HTML content to React elements
    reactElements = parser.parse(htmlContent);
  } catch (error) {
    console.error('Error parsing or converting comment:', error);
  }

  return reactElements;
};
