import ApiMethods from "../ApiMethods";
import ENDPOINTS from "../EndPoints";

class StatusManager {
	static ENDPOINTS = {
		GETDETAILS: (param) => `/status/${param}`,
		SERIALS_PRODUCTION: (pid) => `/parts/${pid}/serials/production/`,
		SERIALS_PRODUCTION_CHANGE: (sid) =>
			`/serials/${sid}/production/change-status/`,
		SERIALS_PRODUCTION_LOGS: (sid) => `/serials/${sid}/production/logs/`,
		LOGS: () => `/parts/logs`,
		AGGREGATED: (param) => `/status/${param}/aggregated`,
	};

	static DETAILS = (param) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.GETDETAILS(param);
		return ApiMethods.get(url);
	};

	static SERIALPROD(pid) {
		const url =
			ENDPOINTS.BASE_URL + this.ENDPOINTS.SERIALS_PRODUCTION(pid);
		return ApiMethods.get(url);
	}

	static CHANGE_SERIALPROD(sid, body) {
		const url =
			ENDPOINTS.BASE_URL + this.ENDPOINTS.SERIALS_PRODUCTION_CHANGE(sid);
		return ApiMethods.post(url, body);
	}

	static SERIALPROD_LOG(sid) {
		const url =
			ENDPOINTS.BASE_URL + this.ENDPOINTS.SERIALS_PRODUCTION_LOGS(sid);
		return ApiMethods.get(url);
	}

	static LOGS(body) {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.LOGS();
		console.log(body)
		return ApiMethods.post(url, body);
	}

	static AGGREGATED(partNumber) {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.AGGREGATED(partNumber);
		return ApiMethods.get(url);
	}
}

export default StatusManager;
