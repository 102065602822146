import ApiMethods from "../ApiMethods";
import ENDPOINTS from "../EndPoints";

class YieldManager {
	static ENDPOINTS = {
		GETPIECHARTDETAIL: () => `/getpiechart`,
		GETPIECHART: (partNumber) => `/part/${partNumber}/pie`,
		GETTESTDETAIL: () => `/gettestinfo`,
		GETTESTDETAILS: (partNumber, station) =>
			`/parts/${partNumber}/station/${station}/yield/`,
		POSTSUMMARYLIST: (partNumber) => `/parts/${partNumber}/yield`,
		POSTSERIALS: (partNumber) => `/parts/${partNumber}/serials`,

		BOXPLOT_GETBOXPLOTDETAIL: () => `/boxplot`,
		BOXPLOT_DATASTUDIO: () => `/getbpdatastudio`,
		BOXPLOT_GETTEST: () => `/gettestbytestname`,
	};

	static POSTPIECHARTDETAIL = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.GETPIECHARTDETAIL();
		return ApiMethods.post(url, body);
	};

	static POSTTESTDETAIL = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.GETTESTDETAIL();
		return ApiMethods.post(url, body);
	};

	static PIECHART = (serial, body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.GETPIECHART(serial);
		return ApiMethods.post(url, body);
	};

	static SUMMARYLIST = (param, body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.POSTSUMMARYLIST(param);
		console.log(url);
		return ApiMethods.post(url, body);
	};

	static SERIALLIST = (param, body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.POSTSERIALS(param);
		return ApiMethods.post(url, body);
	};

	static POSTTESTDETAILS = (partNumber, station, body) => {
		const url =
			ENDPOINTS.BASE_URL + this.ENDPOINTS.GETTESTDETAILS(partNumber, station);
		return ApiMethods.post(url, body);
	};

	static BOXPLOT_DETAIL = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.BOXPLOT_GETBOXPLOTDETAIL();
		return ApiMethods.post(url, body);
	};

	static BOXPLOT_DATASTUDIO = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.BOXPLOT_DATASTUDIO();
		return ApiMethods.post(url, body);
	};
	static BOXPLOT_TEST = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.BOXPLOT_GETTEST();
		return ApiMethods.post(url, body);
	};
}

export default YieldManager;
