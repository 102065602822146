import React, { useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const BuildOrderModal = ({ isOpen, onClose, buildOrders, setBuildOrders }) => {

    const BoxRef = React.useRef(null);

    const [top, setTop] = React.useState(0);

    useEffect(() => {
        const updateTop = () => {
            if (BoxRef.current) {
                setTop((window.innerHeight - BoxRef.current.clientHeight) / 2);
                console.log('Top:', (window.innerHeight - BoxRef.current.clientHeight) / 2);
            }
        }

        updateTop();

        window.addEventListener('resize', updateTop);

        return () => {
            window.removeEventListener('resize', updateTop);
        };
    }, []);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;

        const key = source.droppableId; // Identify the build order
        const reorderedParts = Array.from(buildOrders[key].parts);

        // Remove the dragged part from its original position
        const [movedPart] = reorderedParts.splice(source.index, 1);

        // Insert the dragged part at its new position
        reorderedParts.splice(destination.index, 0, movedPart);

        // Update rank values
        const updatedParts = reorderedParts.map((part, index) => ({ ...part, rank: index }));

        // Update the state with the new order
        setBuildOrders({
            ...buildOrders,
            [key]: {
                ...buildOrders[key],
                parts: updatedParts,
            }
        });
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box ref={BoxRef} sx={{
                width: 800,
                bgcolor: 'background.paper',
                p: 4,
                mx: 'auto',
                mt: `${top}px`, // Center the modal vertically
                borderRadius: 2,
                position: 'relative' // For absolute positioning of the close button
            }}>
                {/* Close Button */}
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: 8, // Top-right corner with some padding
                        right: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DragDropContext onDragEnd={handleOnDragEnd}>
                    {Object.keys(buildOrders).map((key, index) => (
                        <div key={index}>
                            {/* Header with margin-bottom */}
                            <Typography variant='h5' sx={{ marginBottom: '8px' }}>{key}</Typography>

                            <Droppable droppableId={key}>
                                {(provided) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            border: '2px solid #ccc',
                                            padding: '16px',
                                            borderRadius: '8px',
                                        }}
                                    >
                                        {buildOrders[key].parts.map((part, index) => (
                                            <Draggable key={part.partAlias} draggableId={part.partAlias} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                            backgroundColor: '#f0f0f0',
                                                            padding: '8px 16px',
                                                            marginBottom: index !== buildOrders[key].parts.length - 1 ? '8px' : 0,
                                                            borderRadius: '4px',
                                                            border: '1px solid #ccc',
                                                            justifyContent: 'space-between',
                                                            display: 'flex',
                                                            placeItems: 'center',
                                                            ...provided.draggableProps.style,
                                                        }}
                                                    >
                                                        <div style={{display: 'flex', placeItems: 'center', justifyContent: 'center'}}>
                                                            <DragIndicatorIcon style={{transform: 'translateX(-12px)'}} />
                                                            <Typography variant='body1'>
                                                                {part.partAlias}
                                                            </Typography>
                                                        </div>
                                                        <Typography variant='body1'>
                                                            {part.partNumber}
                                                        </Typography>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    ))}
                </DragDropContext>
            </Box>
        </Modal>
    );
};

export default BuildOrderModal;
