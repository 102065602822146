import { Bar } from "react-chartjs-2";

const BarChart = ({ rows, columns, stacked = false, showLabels = false }) => {
    const labels = columns; // Example: ['Jan', 'Feb', 'Mar']

    const datasets = rows.map((row) => ({
        label: row.label,
        data: row.values,
        backgroundColor: row.backgroundColor || `rgba(54, 162, 235, 0.2)`,
        borderWidth: 0,
    }));

    const data = {
        labels: labels,
        datasets: datasets,
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: showLabels,
                position: 'top',
            },
            title: {
                display: false,
                text: stacked ? 'Stacked Bar Chart' : 'Bar Chart',
            },
        },
        scales: {
            x: {
                stacked: stacked,
                ticks: {
                    callback: function (value, index, ticks) {
                        const label = this.getLabelForValue(value);
                        const chartWidth = this.chart.width; // Get chart width
                        const numberOfBars = ticks.length; // Total number of bars
                        const maxLabelLength = Math.floor((chartWidth / numberOfBars) / 16); // Approximate width per character (8px)

                        return label.length > maxLabelLength ? label.slice(0, maxLabelLength) + '...' : label;
                    },
                },
            },
            y: {
                beginAtZero: true,
                stacked: stacked,
            },
        },
    };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Bar data={data} options={options} />
        </div>
    );
};

export default BarChart;
